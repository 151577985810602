import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TextButton from 'scalexp/components/atoms/TextButton';
import Typography from 'scalexp/components/atoms/Typography';
import useActiveOrganisation from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisation';
import Column from 'scalexp/components/layout/Column';
import Row from 'scalexp/components/layout/Row';
import Spacer from 'scalexp/components/layout/Spacer';
import BlurredModal from 'scalexp/components/molecules/BlurredModal';
import { getCurrencyName } from 'scalexp/components/molecules/CurrencySelect/currencySupport';
import { ACCOUNTING_CONNECTIONS } from 'scalexp/features/connections/ConnectionsContext/useGetAccountingAndSalesSystem';
import styled from 'styled-components';

import OnboardingOrganisationCard from '../../../components/molecules/OnboardingOrganisationCard';
import OnboardingPageTitle from '../../../components/molecules/OnboardingPageTitle';
import { useConnectionsContext } from '../../connections/ConnectionsContext';
import QuickbooksConnectionCard from '../../connections/cards/QuickbooksConnectionCard';
import XeroConnectionCard from '../../connections/cards/XeroConnectionCard';
import ZohoBooksConnectionCard from '../../connections/cards/ZohoBooksConnectionCard';

const StyledContainer = styled.div`
  padding-top: 100px;
`;

const StyledConnectionsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(5)};
  flex-wrap: wrap;
`;

const OnboardingConnections = () => {
  const history = useHistory();
  const { consolidatedConnections } = useConnectionsContext();
  const [showConnectionModal, setShowConnectionModal] = useState(false);
  const [selectedOrganisationId, setSelectedOrganisationId] = useState<number | null>(null);
  const organisation = useActiveOrganisation();

  let hasConnectedAccount = false;
  if (consolidatedConnections) {
    hasConnectedAccount = consolidatedConnections.some(org =>
      ACCOUNTING_CONNECTIONS.some(con => org.connections[con].status === 'connected'),
    );
  }

  return (
    <StyledContainer>
      <OnboardingPageTitle title="Set up your organisation" subtitle="Connecting your financial data into ScaleXP" />
      <Spacer margin={{ top: 12, bottom: 4 }}>
        {organisation.consolidation_type === 'PARENT' && (
          <Typography color="secondary" size="medium">
            Consolidated “Parent” Organisation
          </Typography>
        )}
      </Spacer>
      {organisation.consolidation_type !== 'PARENT' ? (
        <OnboardingOrganisationCard organistion={organisation} handleConnect={() => setShowConnectionModal(true)} />
      ) : (
        <>
          <Spacer margin={{ top: 5, bottom: 5 }}>
            <Typography color="black" size="h3">
              {organisation.name}
            </Typography>
            <Spacer margin={{ top: 4 }} />
            <Typography size="medium" weight="regular">
              {getCurrencyName(organisation.default_currency_code)}
            </Typography>
          </Spacer>
          <Spacer margin={{ top: 12, bottom: 4 }}>
            <Typography color="secondary" size="medium">
              Subsidiaries
            </Typography>
          </Spacer>
          <Column>
            {organisation.children.map(organisationsChild => (
              <OnboardingOrganisationCard
                key={organisationsChild.organisation_id}
                organistion={organisationsChild}
                connections={
                  consolidatedConnections?.find(
                    ({ organisation_id }) => organisation_id === organisationsChild.organisation_id,
                  )?.connections
                }
                handleConnect={() => {
                  setSelectedOrganisationId(organisationsChild.organisation_id);
                  setShowConnectionModal(true);
                }}
              />
            ))}
          </Column>
        </>
      )}
      <Spacer margin={{ top: 14 }} padding={{ bottom: 20 }}>
        <Row width="850px" hAlign="flex-end" vAlign="center">
          <TextButton
            width="130px"
            variant="primary"
            size="large"
            loading={false}
            disabled={!hasConnectedAccount}
            onClick={() => history.push('/connections/onboarding/settings')}
          >
            Next
          </TextButton>
        </Row>
      </Spacer>
      <BlurredModal
        showModal={showConnectionModal}
        onHide={() => setShowConnectionModal(false)}
        width="760px"
        headerContent="Add a Connection"
        padding="regular"
      >
        <StyledConnectionsContainer>
          <XeroConnectionCard
            hide="connected"
            isOnboarding={true}
            selectedOrganisationId={selectedOrganisationId || undefined}
          />
          <QuickbooksConnectionCard
            hide="connected"
            isOnboarding={true}
            selectedOrganisationId={selectedOrganisationId || undefined}
          />
          <ZohoBooksConnectionCard
            hide="connected"
            isOnboarding={true}
            selectedOrganisationId={selectedOrganisationId || undefined}
          />
        </StyledConnectionsContainer>
      </BlurredModal>
    </StyledContainer>
  );
};

export default OnboardingConnections;
