import dayjs from 'dayjs';
import React from 'react';

import Typography from '../../atoms/Typography';
import { DATE_FORMAT, DATE_FORMAT_DISPLAY } from '../DateSelect/dateSupport';
import Select, { SelectDataItem, SelectSize } from '../Select';

// Simple component to facilitate in the creation of a Month Year Date picker given
// a start and end month

export type MonthPickerProps = {
  width?: string;
  value?: string;
  start?: Date;
  end?: Date;
  disabled?: boolean;
  sortOrder?: 'ASCENDING' | 'DESCENDING';
  monthOnly?: boolean;
  onChange?: (value: string) => void;
  size?: SelectSize;
  dateFormat?: string;
};

interface Item extends SelectDataItem {
  year: number;
  month: number;
}

export const MonthPicker: React.FC<React.PropsWithChildren<MonthPickerProps>> = ({
  width = '255px',
  start = new Date(new Date().getFullYear() - 4, 1),
  end = new Date(new Date().getFullYear(), new Date().getMonth() + 1),
  sortOrder = 'DESCENDING',
  monthOnly = false,
  disabled = false,
  value,
  onChange,
  size,
  dateFormat = DATE_FORMAT,
}) => {
  const getOptions = () => {
    const options: Item[] = [];
    let date = dayjs(start).startOf('month');
    let endDayJs = dayjs(end).startOf('month');
    while (true) {
      options.push({
        label: date.format(DATE_FORMAT_DISPLAY),
        value: date.format(dateFormat),
        year: date.year(),
        month: date.month(),
      });
      date = date.add(1, 'month').startOf('month');
      if (date >= endDayJs) {
        break;
      }
    }
    return sortOrder === 'ASCENDING' ? options : options.reverse();
  };

  const data = getOptions();

  return (
    <Select
      disabled={disabled}
      size={size}
      width={width}
      selectWidth={270}
      data={data}
      value={value}
      groupBy={monthOnly ? undefined : 'year'}
      expandableGroups
      placeholder="Select Month"
      searchable
      renderValue={value => (
        <Typography>
          {monthOnly ? dayjs(value, dateFormat).format('MMM') : dayjs(value, dateFormat).format(DATE_FORMAT_DISPLAY)}
        </Typography>
      )}
      onChange={onChange}
      renderSelectItem={(_, itemData) => {
        return (
          <div>
            {monthOnly
              ? dayjs(itemData.value, dateFormat).format('MMM')
              : dayjs(itemData.value, dateFormat).format(DATE_FORMAT_DISPLAY)}
          </div>
        );
      }}
    />
  );
};
