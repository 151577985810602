import produce from 'immer';

import {
  ORGANISATIONS_MONTH_END_CATEGORIES_ADD_TASK,
  ORGANISATIONS_MONTH_END_CATEGORIES_LOAD,
  MonthEndCategoriesActions,
  ORGANISATIONS_MONTH_END_CATEGORIES_ADD,
  ORGANISATIONS_MONTH_END_CATEGORY_RENAME,
  ORGANISATIONS_MONTH_END_CATEGORY_DELETE,
  ORGANISATIONS_MONTH_END_CATEGORIES_DELETE_TASK,
  ORGANISATIONS_MONTH_END_CATEGORIES_UPDATE_TASK,
} from './actions';
import { OrganisationMonthEndCategories } from './types';

const MonthEnd = (
  prevState: OrganisationMonthEndCategories = {},
  action: MonthEndCategoriesActions,
): OrganisationMonthEndCategories => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_MONTH_END_CATEGORIES_LOAD: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload.results,
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      case ORGANISATIONS_MONTH_END_CATEGORIES_ADD: {
        switch (action.status) {
          case 'success':
            const { organisationId } = action.params;
            state[organisationId] = {
              ...state[organisationId],
              value: [...(state[organisationId]?.value ?? []), { ...action.payload, tasks: [] }],
            };
            return;
          default:
            return state;
        }
      }
      case ORGANISATIONS_MONTH_END_CATEGORY_DELETE: {
        switch (action.status) {
          case 'success':
            const { organisationId } = action.params;
            state[organisationId] = {
              ...state[organisationId],
              value: state[organisationId]?.value!.filter(category => category.id !== action.params.categoryId),
            };
            return;
          default:
            return state;
        }
      }
      case ORGANISATIONS_MONTH_END_CATEGORY_RENAME: {
        switch (action.status) {
          case 'success':
            const { organisationId } = action.params;
            state[organisationId] = {
              ...state[organisationId],
              value: state[organisationId]?.value!.map(category => {
                if (category.id === action.params.categoryId) {
                  return {
                    ...category,
                    name: action.payload.name,
                  };
                }
                return category;
              }),
            };
            return;
          default:
            return state;
        }
      }

      case ORGANISATIONS_MONTH_END_CATEGORIES_ADD_TASK: {
        switch (action.status) {
          case 'success':
            const { organisationId } = action.params;
            state[organisationId] = {
              ...state[organisationId],
              value: state[organisationId]?.value!.map(category => {
                if (category.id === action.payload.category) {
                  return {
                    ...category,
                    name: category.name,
                    tasks: [...category.tasks, { ...action.payload, is_active: true, sub_tasks: [] }],
                  };
                }
                return category;
              }),
            };
            return;
          default:
            return state;
        }
      }
      case ORGANISATIONS_MONTH_END_CATEGORIES_DELETE_TASK: {
        switch (action.status) {
          case 'success':
            const { organisationId } = action.params;
            state[organisationId] = {
              ...state[organisationId],
              value: state[organisationId]?.value!.map(category => {
                if (category.id === action.params.categoryId) {
                  return {
                    ...category,
                    tasks: category.tasks.filter(task => task.id !== action.params.taskId),
                  };
                }
                return category;
              }),
            };
            return;
          default:
            return state;
        }
      }
      case ORGANISATIONS_MONTH_END_CATEGORIES_UPDATE_TASK: {
        switch (action.status) {
          case 'success':
            const { organisationId, isSubTask } = action.params;
            if (isSubTask) {
              state[organisationId] = {
                ...state[organisationId],
                value: state[organisationId]?.value!.map(category => {
                  return {
                    ...category,
                    tasks: category.tasks.map(task => {
                      return {
                        ...task,
                        sub_tasks: task.sub_tasks.map(subTask => {
                          if (subTask.id === action.payload.id) {
                            return action.payload;
                          }
                          return subTask;
                        }),
                      };
                    }),
                  };
                }),
              };
              return;
            }
            state[organisationId] = {
              ...state[organisationId],
              value: state[organisationId]?.value!.map(category => {
                if (category.id === action.payload.category) {
                  return {
                    ...category,
                    tasks: category.tasks.map(task => {
                      if (task.id === action.payload.id) {
                        return action.payload;
                      }
                      return task;
                    }),
                  };
                }
                return category;
              }),
            };
            return;
          default:
            return state;
        }
      }
      default:
        return state;
    }
  });
};

export default MonthEnd;
