import React from 'react';
import useActiveOrganisation from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisation';
import { Connection } from 'scalexp/components/organisms/OAuthConnectionsManager';
import fetcher from 'scalexp/utils/fetcher';
import useSWR from 'swr';

import { INITIAL_CONNECTIONS, ConnectionStatuses, ConsolidatedConnection, ConsolidatedMappedConnection } from './types';

export interface ConnectionContextProps {}

export const ConnectionsContext = React.createContext<{
  connections: ConnectionStatuses;
  consolidatedConnections: ConsolidatedMappedConnection[];
  revalidate: VoidFunction;
  isLoaded: boolean;
}>(undefined!);

export const ConnectionsProvider: React.FC<React.PropsWithChildren<ConnectionContextProps>> = ({ children }) => {
  const organisation = useActiveOrganisation();
  const organisationId = organisation.organisation_id;
  let mappedConsolidatedConnections: ConsolidatedMappedConnection[] = [];

  const { data, mutate, error: connectionsError } = useSWR<ConsolidatedConnection[]>(
    `/api/v1/${organisationId}/connections`,
    (key: string) => fetcher(`/api/v1/${organisationId}/connections`).then(res => res),
  );
  mappedConsolidatedConnections = (data || []).map(org => {
    const mappedConnectionsChild: ConnectionStatuses = (org.connections || []).reduce(
      (acc, connection) => ({
        ...acc,
        [connection.source_name]: connectionsError
          ? { status: 'error', error: connectionsError }
          : connection.connected
          ? { status: 'connected', ...connection }
          : { status: 'needs-relink' },
      }),
      INITIAL_CONNECTIONS,
    );
    return { ...org, connections: mappedConnectionsChild };
  });

  const currentOrg = Object.values(data?.find(org => org.organisation_id === organisationId)?.connections || {});

  const mappedConnections: ConnectionStatuses = ((currentOrg as Connection[]) || []).reduce(
    (acc, connection) => ({
      ...acc,
      [connection.source_name]: connectionsError
        ? { status: 'error', error: connectionsError }
        : connection.connected
        ? { status: 'connected', ...connection }
        : { status: 'needs-relink' },
    }),
    INITIAL_CONNECTIONS,
  );

  if (connectionsError) {
    return <div>Failed to retrieve connections</div>;
  }

  return (
    <ConnectionsContext.Provider
      value={{
        consolidatedConnections: mappedConsolidatedConnections,
        connections: mappedConnections,
        revalidate: mutate,
        isLoaded: !!data,
      }}
    >
      {children}
    </ConnectionsContext.Provider>
  );
};

export const useConnectionsContext = () => {
  const value = React.useContext(ConnectionsContext);

  if (!value) {
    throw new Error('ConnectionsProvider not found!');
  }

  return value;
};
