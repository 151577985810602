import React, { useCallback } from 'react';

type PromiseResolver<T> = (value: T) => void;

const usePromise = function <T>(): [() => Promise<T>, PromiseResolver<T>, boolean] {
  const [r, setResolve] = React.useState<PromiseResolver<T> | null>(null);
  const getPromise = useCallback(
    () =>
      new Promise<T>(resolve => {
        setResolve(() => resolve);
      }),
    [],
  );

  const resolvePromise = useCallback((value: T) => {
    setResolve((resolve: PromiseResolver<T> | null) => {
      resolve?.(value);
      return null;
    });
  }, []);

  return [getPromise, resolvePromise, r !== null];
};

export default usePromise;
