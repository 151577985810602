import { FC, useEffect } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';

type FormAutoSubmitProps = {
  delay?: number;
  onSubmit: SubmitHandler<any>;
};

const FormAutoSubmit: FC<FormAutoSubmitProps> = ({ onSubmit, delay = 1000 }) => {
  const { handleSubmit, watch } = useFormContext();

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    const subscription = watch(() => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        handleSubmit(onSubmit)();
      }, delay);
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch, delay]);

  return null;
};

export default FormAutoSubmit;
