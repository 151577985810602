import { useMemo } from 'react';
import useActiveOrganisation from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisation';
import { useDeferredRevenueConfigs } from 'scalexp/features/deferred-revenue/deferredRevenueConfigHooks';
import { DeferredRevenueConfig } from 'scalexp/features/deferred-revenue/types';
import usePickerOptions from 'scalexp/features/report-editor/ReportEditor/usePickerOptions';
import { MetricSchema2, MetricSchema2Data } from 'scalexp/utils/metrics/metricSchema2';

import { useOrganisationContext } from '../../../components/contexts/OrganisationContext';
import useActiveOrganisationId from '../../../components/contexts/OrganisationContext/useActiveOrganisationId';
import { MetricDetails, ValueBasis } from '../../../components/organisms/MetricSchemaChart/types';
import { useBudgetSets } from '../../../store/state/budgetsets/hooks';
import { useConsolidatedBudgetSets } from '../../../store/state/consolidatedBudgetsets/hooks';
import { useDerivedMetrics } from '../../../store/state/derivedMetrics/hooks';
import { useNativeMetricNames } from '../../../store/state/nativeMetrics/hooks';
import { theme } from '../../../theme';
import { Chart } from './types';

export const useDataProvenanceMetricDetails = (config?: Chart): MetricDetails[] => {
  const organisationId = useActiveOrganisationId();
  const organisation = useOrganisationContext();
  const nativeMetricNamesVS = useNativeMetricNames();
  const derivedMetricsVS = useDerivedMetrics(organisationId);
  const consolidatedBudgetSetsVS = useConsolidatedBudgetSets(organisationId);
  const budgetSetsVS = useBudgetSets(organisationId);
  const derivedMetrics = derivedMetricsVS.value;
  const nativeMetricNames = nativeMetricNamesVS.value;
  const defaultBudget =
    organisation.consolidation_type === 'PARENT'
      ? organisation.default_consolidated_budget_set_id
      : organisation.default_budget_set_id;
  if (!config || !config?.chart_data) {
    return [];
  }
  const isPieChart = config.chart_type === 'pie_chart';
  const isKPIChart = config.chart_type === 'kpi_chart';
  const seriesArray =
    isKPIChart || isPieChart
      ? [
          {
            metricSchema: config.chart_data.metric_schema,
            name: config.name,
            valueBasis: config.chart_data.value_basis,
            budget: config.chart_data.budget,
            consolidatedBudget: undefined,
            color: isPieChart ? config.chart_data.color : theme.palette.primary.main,
          },
        ]
      : config.chart_data?.series.map(series => ({
          metricSchema: series.metric_schema,
          name: series.name,
          valueBasis: series.value_basis,
          budget: series.budget,
          consolidatedBudget: series.consolidated_budget,
          color: series.color,
        })) ?? [];

  if (!nativeMetricNames || !derivedMetrics) {
    return [];
  }

  let metrics: MetricDetails[] = seriesArray.map((series, index) => {
    let metricName: string;
    const budgetSetId: number | undefined =
      series.budget ||
      (organisation.consolidation_type === 'PARENT'
        ? organisation.default_consolidated_budget_set_id
        : organisation.default_budget_set_id);
    const budgetSet =
      organisation.consolidation_type === 'PARENT'
        ? consolidatedBudgetSetsVS.value?.find(budgetSet => budgetSet.id === budgetSetId)
        : budgetSetsVS.value?.find(budgetSet => budgetSet.id === budgetSetId);
    let valueBasis = series.valueBasis === 'ACTUAL' ? 'actuals' : budgetSet ? budgetSet.name : 'budget';
    let metric = series?.metricSchema?.nodes;
    let metricSchemaData: MetricSchema2Data = metric?.[0] as MetricSchema2Data;
    let singleMetric = metric.length === 1;
    if (singleMetric && metricSchemaData?.data?.operator === 'native') {
      let metricId = metricSchemaData?.data?.metricId;
      metricName = `${nativeMetricNames[metricId]} ${valueBasis}`;
    } else if (singleMetric && metricSchemaData?.data?.operator === 'derived') {
      let metricId = metricSchemaData?.data?.metricId;
      metricName = `${derivedMetrics[metricId].name} ${valueBasis}`;
    } else {
      metricName = series.name || '';
    }

    return {
      // @ts-ignore
      metric: series.metricSchema as MetricSchema2,
      valueBasis: series.valueBasis as ValueBasis,
      period: config.chart_data.period,
      color: series.color,
      title: metricName,
      budgetId:
        series.valueBasis.toLocaleLowerCase() === 'actual'
          ? undefined
          : series.budget ?? series.consolidatedBudget ?? defaultBudget ?? null,
    };
  });
  return metrics;
};

export const useChartDeferredRevenueConfigOptions = () => {
  const organisation = useActiveOrganisation();
  const { data: deferredRevenueConfigs } = useDeferredRevenueConfigs();
  return useMemo(() => {
    const configOptions: {
      value: DeferredRevenueConfig['id'] | null;
      label: string;
    }[] = [
      ...Object.values(deferredRevenueConfigs || {}).map(config => ({
        value: config.id,
        label: config.name,
      })),
      {
        value: null,
        label: organisation.default_deferred_revenue_config_id
          ? `Default (${deferredRevenueConfigs?.[organisation.default_deferred_revenue_config_id].name})`
          : 'Default',
      },
    ].sort((a, b) => a.label.localeCompare(b.label));
    return configOptions;
  }, [deferredRevenueConfigs, organisation]);
};

export const useChartMetricsOptions = (isComplexCase: boolean) => {
  const pickerOptions = usePickerOptions(undefined, true);

  return useMemo(() => {
    const options = [];
    pickerOptions.forEach(option => {
      const [type] = option.id.split(':');
      if (['dm', 'nm', 'account', 'sm', 'pm', 'ps', 'group', 'pt', 'ir'].includes(type)) {
        options.push({
          ...option,
          label: option.name,
          value: option.id,
          group: option.role,
        });
      }
    });
    if (isComplexCase) {
      options.push({
        id: '',
        group: '',
        source_id: '',
        role: '',
        name: 'Custom Formula',
        label: 'Custom Formula',
        value: '',
      });
    }
    return options;
  }, [pickerOptions, isComplexCase]);
};
