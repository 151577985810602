import React from 'react';
import BasicConnectionCard, { ChildConnectionCardProps } from 'scalexp/features/connections/cards/BasicConnectionCard';
import RegionSelectionModal, {
  RegionSelectValues,
} from 'scalexp/features/connections/cards/ZohoBooksConnectionCard/RegionSelectionModal';
import usePromise from 'scalexp/utils/usePromise';

const ZohoBooksConnectionCard: React.FC<ChildConnectionCardProps> = props => {
  const [getRegionPromise, resolveRegion, showModal] = usePromise<RegionSelectValues | null>();
  const handleModalClose = () => resolveRegion(null);
  const handleModalSubmit = (values: RegionSelectValues) => resolveRegion(values);

  return (
    <>
      <BasicConnectionCard
        name="zohobooks"
        logo="/images/logos/zohobooks-banner.png"
        getAdditionalConnectionParams={getRegionPromise}
        {...props}
      />
      <RegionSelectionModal showModal={showModal} closeModal={handleModalClose} onSubmit={handleModalSubmit} />
    </>
  );
};

export default ZohoBooksConnectionCard;
