import React from 'react';
import BasicConnectionCard, { ChildConnectionCardProps } from 'scalexp/features/connections/cards/BasicConnectionCard';

const QuickbooksConnectionCard: React.FC<ChildConnectionCardProps> = props => (
  <BasicConnectionCard
    name="quickbooks"
    logo={'/images/logos/quickbooks-banner.png'}
    connectImg={'logos/quickbooks-connect.png'}
    connectImgHover={'logos/quickbooks-connect-hover.png'}
    {...props}
  />
);

export default QuickbooksConnectionCard;
