import debounce from 'lodash/debounce';
import React, { useMemo, useState } from 'react';
import { useChartDeferredRevenueConfigOptions, useChartMetricsOptions } from 'scalexp/features/chart/ChartCard/hooks';
import { DeferredRevenueConfig } from 'scalexp/features/deferred-revenue/types';
import { SalesMetricIds } from 'scalexp/service/types/metricSchema';
import { PipelineMetricsTypes, PipelineStageMetricsTypes } from 'scalexp/store/state/pipelines/types';
import {
  MetricSchema2,
  MetricSchema2Data,
  MetricSchema2DataSource,
  MetricSchema2DataSourceDerivedMetric,
  MetricSchema2DataSourceEntityAccount,
  MetricSchema2DataSourceInvoicedRevenue,
  MetricSchema2DataSourceNativeMetric,
  MetricSchema2Simple,
} from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import Icon from '../../../components/atoms/Icon';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import { useEditChartContext } from '../../../components/contexts/NewEditChartContext';
import { useChartDataType } from '../../../components/contexts/NewEditChartContext/useChartDataType';
import { useOrganisationContext } from '../../../components/contexts/OrganisationContext';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import ColourPicker from '../../../components/molecules/ColourPicker';
import Message from '../../../components/molecules/Message';
import Select from '../../../components/molecules/Select';
import Switcher from '../../../components/molecules/Switcher';
import { ValueBasis } from '../../../components/organisms/MetricSchemaChart/types';
import { theme } from '../../../theme';
import { WaterfallChart } from '../../chart/WaterfallChart/types';
import ChartEditorAddRowSelect from '../ChartEditorAddRowSelect';
import {
  ACTUAL_BUDGET_OPTIONS,
  DeleteSeriesContainer,
  SeriesOptionContainer,
  StyledSidebarColorPicker,
  StyledSidebarInput,
  SwitcherSidebarWrapper,
} from '../NewChartsSidebar';
import { pipelineMetricOptions, pipelineTypeOptions, stageMetricOptions } from '../TimeChartSeries/options';

interface WaterFallChartSeriesPropsType {
  index: number;
  budgetOptions: (
    | {
        label: string;
        value: number;
      }
    | {
        label: string;
        value: null;
      }
  )[];
  consolidatedBudgetOptions: (
    | {
        label: string;
        value: number;
      }
    | {
        label: string;
        value: null;
      }
  )[];
  handleConfigure: (metricSchema: MetricSchema2, index: number) => void;
}
const StyledMessage = styled(Message)`
  line-height: inherit;
`;
const WaterFallChartSeries: React.FC<WaterFallChartSeriesPropsType> = ({
  index,
  budgetOptions,
  handleConfigure,
  consolidatedBudgetOptions,
}) => {
  const { setConfig, chart: chartToEdit, activeSeries, setActiveSeries } = useEditChartContext();
  const chart = chartToEdit as WaterfallChart;
  const series = chart.chart_data.series[index];
  const organisation = useOrganisationContext();
  const [showDelete, setShowDelete] = useState(false);

  const color = series.color;
  const valueBasis = series.value_basis;
  const budgetSetId = series.budget;
  const consolidatedBudgetSetId = series.consolidated_budget;
  const { chartDataType, getSeriesDataType } = useChartDataType(chart);
  const metricSchema: MetricSchema2 = series.metric_schema;
  const firstNode: MetricSchema2Data | null = metricSchema.schemaType === 'simple' ? metricSchema.nodes[0] : null;

  const isComplexCase: boolean = metricSchema.schemaType === 'complex';
  const isPipeline = firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'pipeline';
  const isStage = firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'stage';
  const isSales = firstNode?.data?.operator === 'sales';
  const isInvoicedRevenue = firstNode?.data?.operator === 'invoiced-revenue';
  const configOptions = useChartDeferredRevenueConfigOptions();
  const normalizedPickerOptions = useChartMetricsOptions(isComplexCase);

  const getSelectedValue = () => {
    // complex formula
    if (metricSchema.schemaType === 'complex') {
      return '';
    } else if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'group') {
      return `group:${firstNode.data.groupId}`;
    } else if (firstNode?.data?.operator === 'sales' && firstNode?.data?.metricId) {
      const isRenewal = firstNode.data.metricId.startsWith('RENEWALS');
      return `pt:${isRenewal ? 'RENEWALS' : 'NEW_SALES'}`;
    } else if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'pipeline') {
      return `pm:${firstNode.data.pipelineId}`;
    } else if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'stage') {
      return `ps:${firstNode.data.stageId}:${firstNode.data.pipelineId}`;
    }
    // get value from the choices
    else {
      return normalizedPickerOptions.find(option =>
        [
          (firstNode!.data as
            | MetricSchema2DataSourceNativeMetric
            | MetricSchema2DataSourceDerivedMetric
            | MetricSchema2DataSourceInvoicedRevenue).metricId,
          (firstNode!.data as MetricSchema2DataSourceEntityAccount).accountId?.toString(),
        ].includes(option.id.split(':')[1]),
      )?.id;
    }
  };
  const pickerOptionValue = getSelectedValue();

  const handleColorChange = (color: string, index: number) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.map((series, seriesIndex) => {
          if (seriesIndex === index) {
            return {
              ...series,
              color,
            };
          }

          return series;
        }),
      },
    });
  };
  const handleValueBasisChange = (value: ValueBasis, index: number) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.map((series, seriesIndex) => {
          if (seriesIndex === index) {
            return {
              ...series,
              value_basis: value,
              budget: undefined,
              consolidated_budget: undefined,
            };
          }

          return series;
        }),
      },
    });
  };
  const handleDeleteSeries = (index: number) => {
    if (chart.chart_data.series[index - 1]) {
      setActiveSeries(chart.chart_data.series[index - 1].id);
    } else if (chart.chart_data.series[index + 1]) {
      setActiveSeries(chart.chart_data.series[index + 1].id);
    } else {
      setActiveSeries('');
    }
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.filter((_, seriesIndex) => seriesIndex !== index),
      },
    });
  };
  const handleChangeSeriesName = (value: string, index: number) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.map((series, seriesIndex) => {
          if (seriesIndex === index) {
            return {
              ...series,
              name: value,
            };
          }

          return series;
        }),
      },
    });
  };
  const handleBudgetSet = (value: number | null, index: number) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.map((series, seriesIndex) => {
          if (seriesIndex === index) {
            return {
              ...series,
              budget: organisation.consolidation_type === 'PARENT' ? null : value,
              consolidated_budget: organisation.consolidation_type === 'PARENT' ? value : null,
            };
          }

          return series;
        }),
      },
    });
  };
  const handleAccountSelection = (newValue: string | null) => {
    if (!newValue) {
      return;
    }
    const [type, newId, secondId] = newValue.split(':');
    let newSimpleMetricSchemaDataSource: MetricSchema2DataSource;
    // Pipeline Type
    if (type === 'pt') {
      newSimpleMetricSchemaDataSource = {
        operator: 'sales',
        metricId: `${newId}_IN_PROGRESS_ACV` as SalesMetricIds,
      };
    } else if (type === 'nm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'native',
        metricId: newId,
        isCashflow: false,
      };
    } else if (type === 'dm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'derived',
        metricId: newId,
      };
    } else if (type === 'ir') {
      newSimpleMetricSchemaDataSource = {
        operator: 'invoiced-revenue',
        metricId: newId,
      };
    } else if (type === 'sm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'sales',
        metricId: newId as SalesMetricIds,
      };
    } else if (type === 'pm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'pipeline',
        pipelineId: newId,
        pipelineMetricId: 'metric_deals_won',
      };
    } else if (type === 'ps' && secondId) {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'stage',
        pipelineId: secondId,
        stageId: newId,
        stageMetricId: 'metric_deals',
      };
    } else if (type === 'account') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'account',
        accountId: parseInt(newId),
        isCashflow: false,
      };
    } else if (type === 'group') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'group',
        groupId: parseInt(newId),
        isCashflow: false,
      };
    } else {
      throw new Error('Unknown type');
    }

    const newSimpleMetricSchema: MetricSchema2Simple = {
      schemaType: 'simple',
      nodes: [
        {
          operator: 'add',
          data: newSimpleMetricSchemaDataSource,
        },
      ],
      decimals: 0,
    };

    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.map((series, seriesIndex) => {
          if (seriesIndex === index) {
            return {
              ...series,
              metric_schema: newSimpleMetricSchema,
            };
          }
          return series;
        }),
      },
    });
  };

  const getSelectedPipelineMetricValue = () => {
    if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'pipeline') {
      return firstNode.data.pipelineMetricId;
    }
  };
  const getSelectedPipelineStageMetricValue = () => {
    if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'stage') {
      return firstNode.data.stageMetricId;
    }
  };

  const getSelectedSalesMetricValue = () => {
    if (firstNode?.data?.operator === 'sales' && firstNode?.data?.metricId) {
      return firstNode.data.metricId;
    }
  };
  const getInvoicedRevenueValue = () => {
    if (firstNode?.data?.operator === 'invoiced-revenue' && firstNode?.data.configId) {
      return firstNode.data.configId;
    }
  };
  const handlePipelineMetricSelection = (newValue: string | null) => {
    if (!newValue || !firstNode) {
      return;
    }
    const seriesCopy = [...chart.chart_data.series];
    // @ts-ignore
    seriesCopy[index].metric_schema.nodes[0].data.pipelineMetricId = newValue as PipelineMetricsTypes;
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: seriesCopy,
      },
    });
  };

  const handleSalesMetricSelection = (salesMetricId: string | null, isRenewal: boolean) => {
    if (!salesMetricId || !firstNode) {
      return;
    }
    const prefix = isRenewal ? 'RENEWALS' : 'NEW_SALES';
    const seriesCopy = [...chart.chart_data.series];
    // @ts-ignore
    seriesCopy[index].metric_schema.nodes[0].data.metricId = `${prefix}_${salesMetricId}` as SalesMetricIds;
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: seriesCopy,
      },
    });
  };
  const handleStageMetricSelection = (newValue: string | null) => {
    if (!newValue || !firstNode) {
      return;
    }
    const seriesCopy = [...chart.chart_data.series];
    // @ts-ignore
    seriesCopy[index].metric_schema.nodes[0].data.stageMetricId = newValue as PipelineStageMetricsTypes;
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: seriesCopy,
      },
    });
  };
  const handleUpdateInvoicedRevenueValue = (newValue: string | null) => {
    if (!newValue || !firstNode) {
      return;
    }
    const seriesCopy = [...chart.chart_data.series];
    // @ts-ignore
    seriesCopy[index].metric_schema.nodes[0].data.configId = newValue;
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: seriesCopy,
      },
    });
  };
  const debounceUpdateSerieName = useMemo(() => debounce(handleChangeSeriesName, 2000), []);
  const salesMetricValue = getSelectedSalesMetricValue()?.replace('RENEWALS_', '').replace('NEW_SALES_', '');
  return (
    <SeriesOptionContainer
      active={activeSeries === series.id}
      onClick={() => {
        if (!showDelete) {
          setActiveSeries(series.id);
        }
      }}
    >
      <DeleteSeriesContainer visibility={showDelete}>
        <Column hAlign="center" spacing="small">
          <Icon name="delete" color={theme.palette.granite} size={6} />
          <Typography size="small" weight="medium" color="black">
            Are you sure you want to delete
          </Typography>
          <Typography size="small" weight="regular" color="black" display="block">
            {series.name}
          </Typography>
          <Row vAlign="center" spacing="medium" onClick={() => setShowDelete(false)}>
            <TextButton variant="secondary" width="110px">
              Cancel
            </TextButton>
            <TextButton variant="danger" width="110px" onClick={() => handleDeleteSeries(index)}>
              Delete
            </TextButton>
          </Row>
        </Column>
      </DeleteSeriesContainer>
      <Row hAlign="end" vAlign="center">
        <Icon clickable name="delete" color={theme.palette.granite} size={6} onClick={() => setShowDelete(true)} />
      </Row>
      <Row hAlign="space-between" vAlign="center">
        <StyledSidebarInput
          width="100%"
          defaultValue={series.name}
          onChange={e => debounceUpdateSerieName(e.target.value, index)}
        />
        <StyledSidebarColorPicker>
          <ColourPicker handleColor={color => handleColorChange(color, index)} color={color} />
        </StyledSidebarColorPicker>
      </Row>
      <Column width="100%">
        <Row hAlign="space-between" vAlign="center">
          <ChartEditorAddRowSelect
            size="medium"
            width={metricSchema.schemaType === 'complex' ? '247px' : '300px'}
            includeFixedNumber={false}
            includeBrackets={false}
            selectWidth={360}
            data={normalizedPickerOptions}
            onChange={handleAccountSelection}
            value={pickerOptionValue!}
            handleFormulaCategorySelected={() =>
              handleConfigure(
                {
                  schemaType: 'complex',
                  nodes: [],
                  decimals: 0,
                  dataType: 'monetary',
                  impact: 'neutral',
                  aggregation: 'sum',
                },
                index,
              )
            }
          />
          {metricSchema.schemaType === 'complex' && (
            <Icon
              clickable
              name="settings"
              size={6}
              color={theme.palette.granite}
              onClick={() => handleConfigure(metricSchema, index)}
            />
          )}
        </Row>

        {normalizedPickerOptions.find(option => option.value === pickerOptionValue) === undefined && (
          <StyledMessage variant="danger" withIcon>
            The selected {isPipeline ? 'pipeline' : isStage ? 'stage' : ''} no longer exists. Please choose a different
            option from the dropdown menu above.
          </StyledMessage>
        )}
      </Column>
      {isSales && !isComplexCase && (
        <Row hAlign="space-between" vAlign="center">
          <Select
            size="medium"
            width="100%"
            placeholder={'Select a sales metric'}
            selectHeight={300}
            selectWidth={338}
            data={pipelineTypeOptions}
            onChange={value =>
              handleSalesMetricSelection(value, !!getSelectedSalesMetricValue()?.startsWith('RENEWALS_'))
            }
            value={salesMetricValue}
          />
        </Row>
      )}
      {isPipeline && !isComplexCase && (
        <Row hAlign="space-between" vAlign="center">
          <Select
            size="medium"
            width="100%"
            placeholder={'Select a pipeline metric'}
            selectHeight={300}
            selectWidth={338}
            data={pipelineMetricOptions}
            onChange={handlePipelineMetricSelection}
            value={getSelectedPipelineMetricValue()}
          />
        </Row>
      )}
      {isStage && !isComplexCase && (
        <Row hAlign="space-between" vAlign="center" width="100%">
          <Select
            size="medium"
            width="100%"
            placeholder={'Select a stage metric'}
            selectHeight={300}
            data={stageMetricOptions}
            onChange={handleStageMetricSelection}
            value={getSelectedPipelineStageMetricValue()}
          />
        </Row>
      )}
      {isInvoicedRevenue && !isComplexCase && (
        <Row hAlign="space-between" vAlign="center" width="100%">
          <Select<DeferredRevenueConfig['id'] | null>
            width="100%"
            size="medium"
            selectWidth={300}
            data={configOptions}
            value={getInvoicedRevenueValue()}
            onChange={value => {
              handleUpdateInvoicedRevenueValue?.(value);
            }}
            placeholder={'Config'}
            onClick={e => e.stopPropagation()}
          />
        </Row>
      )}

      <SwitcherSidebarWrapper>
        <Switcher
          width="100%"
          size="small"
          data={ACTUAL_BUDGET_OPTIONS}
          defaultColor={theme.palette.white}
          activeColor={theme.palette.granite}
          value={valueBasis}
          onChange={value => handleValueBasisChange(value as ValueBasis, index)}
        />
      </SwitcherSidebarWrapper>
      {organisation.consolidation_type !== 'PARENT' && valueBasis === 'BUDGET' && (
        <Select
          size="medium"
          width="100%"
          placeholder="Budget name"
          data={budgetOptions}
          value={budgetSetId}
          onChange={value => {
            handleBudgetSet(value, index);
          }}
        />
      )}

      {organisation.consolidation_type === 'PARENT' && valueBasis === 'BUDGET' && (
        <Select
          size="medium"
          width="100%"
          placeholder="Consolidated budget name"
          data={consolidatedBudgetOptions}
          value={consolidatedBudgetSetId}
          onChange={value => {
            handleBudgetSet(value, index);
          }}
        />
      )}
      {chartDataType && getSeriesDataType(series) !== getSeriesDataType(chart.chart_data.series[0]) ? (
        <Row width="100%">
          <Message variant="warning" withIcon>
            This series have a different type than series 1
          </Message>
        </Row>
      ) : null}
    </SeriesOptionContainer>
  );
};

export default WaterFallChartSeries;
