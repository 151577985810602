import { useConnectionsContext } from './index';
import type { ConnectionStatus } from './types';

const useConnection = (connectionId: string): ConnectionStatus => {
  const { connections } = useConnectionsContext();

  if (!connections) {
    throw new Error('No ConnectionProvider found');
  }

  const connection = connections[connectionId];
  if (connection) {
    return connection;
  }

  return {
    status: 'error',
    error: `'Unknown connection '${connectionId}'`,
  };
};

export default useConnection;
