import { debounce } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useChartDeferredRevenueConfigOptions, useChartMetricsOptions } from 'scalexp/features/chart/ChartCard/hooks';
import { DeferredRevenueConfig } from 'scalexp/features/deferred-revenue/types';
import {
  MetricSchema2,
  MetricSchema2Data,
  MetricSchema2DataSource,
  MetricSchema2DataSourceDerivedMetric,
  MetricSchema2DataSourceEntityAccount,
  MetricSchema2DataSourceInvoicedRevenue,
  MetricSchema2DataSourceNativeMetric,
  MetricSchema2Simple,
} from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import Divider from '../../../components/atoms/Divider';
import Icon from '../../../components/atoms/Icon';
import TextButton from '../../../components/atoms/TextButton';
import Toggle from '../../../components/atoms/Toggle';
import Typography from '../../../components/atoms/Typography';
import { useEditChartContext } from '../../../components/contexts/NewEditChartContext';
import { useChartDataType } from '../../../components/contexts/NewEditChartContext/useChartDataType';
import { useOrganisationContext } from '../../../components/contexts/OrganisationContext';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import ColourPicker from '../../../components/molecules/ColourPicker';
import Message from '../../../components/molecules/Message';
import Select from '../../../components/molecules/Select';
import Switcher from '../../../components/molecules/Switcher';
import Tooltip from '../../../components/molecules/Tooltip';
import { ValueBasis } from '../../../components/organisms/MetricSchemaChart/types';
import { SalesMetricIds } from '../../../service/types/metricSchema';
import { PipelineMetricsTypes, PipelineStageMetricsTypes } from '../../../store/state/pipelines/types';
import { theme } from '../../../theme';
import { TimeChart, TimeChartData } from '../../chart/TimeChart/types';
import ChartEditorAddRowSelect from '../ChartEditorAddRowSelect';
import {
  ACTUAL_BUDGET_OPTIONS,
  PAST_MONTH_OPTIONS,
  FUTURE_MONTH_OPTIONS,
  DeleteSeriesContainer,
  SeriesOptionContainer,
  SERIES_TYPES_OPTIONS,
  StyledSidebarColorPicker,
  StyledSidebarInput,
  SwitcherSidebarWrapper,
  StyledDisabledSeriesName,
} from '../NewChartsSidebar';
import { pipelineMetricOptions, pipelineTypeOptions, stageMetricOptions } from './options';

const StyledMessage = styled(Message)`
  line-height: inherit;
`;
const ChartChoice = styled.div<{ selected: boolean }>`
  width: ${({ theme }) => theme.sizing(20)};
  height: ${({ theme }) => theme.sizing(10)};
  position: relative;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.sizing(2)};

  & img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }

  ${({ selected, theme }) => {
    if (selected) {
      return `border: 2px solid ${theme.palette.granite};`;
    }
  }}
`;

const StyledTooltip = styled(Tooltip)`
  border: 1px solid ${({ theme }) => theme.palette.silver};
  border-radius: 14px;
`;

const SpacedRow = styled(Row)`
  margin-top: 8px;
`;

interface TimeChartSeriesPropsType {
  index: number;
  budgetOptions: (
    | {
        label: string;
        value: number;
      }
    | {
        label: string;
        value: null;
      }
  )[];
  consolidatedBudgetOptions: (
    | {
        label: string;
        value: number;
      }
    | {
        label: string;
        value: null;
      }
  )[];
  handleConfigure: (metricSchema: MetricSchema2, index: number) => void;
}

const TimeChartSeries: React.FC<TimeChartSeriesPropsType> = ({
  index,
  budgetOptions,
  consolidatedBudgetOptions,
  handleConfigure,
}) => {
  const { setConfig, chart: chartToEdit, activeSeries, setActiveSeries } = useEditChartContext();
  const chart = chartToEdit as TimeChart;
  const period = chart.chart_data.period;
  const organisation = useOrganisationContext();
  const [showDelete, setShowDelete] = useState(false);
  const series = chart.chart_data.series[index];
  const firstSameSideSeriesIndex = chart.chart_data.series.findIndex(
    seriesItem => seriesItem.y_axis_side === series.y_axis_side,
  );
  const before = (series.offset || 0) - series.periods + 1;
  const after = series.offset || 0;
  const seriesType = series.type === 'ColumnSeries' && series.stacked ? 'Stacked' : series.type;
  const color = series.color;
  const valueBasis = series.value_basis;
  const budgetSetId = series.budget;
  const consolidatedBudgetSetId = series.consolidated_budget;
  const metricSchema: MetricSchema2 = series.metric_schema;
  const { getSeriesDataType } = useChartDataType(chart);
  const firstNode: MetricSchema2Data | null = metricSchema.schemaType === 'simple' ? metricSchema.nodes[0] : null;

  const isComplexCase: boolean = metricSchema.schemaType === 'complex';

  const isPipeline = firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'pipeline';
  const isStage = firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'stage';
  const isSales = firstNode?.data?.operator === 'sales';
  const isInvoicedRevenue = firstNode?.data?.operator === 'invoiced-revenue';
  const configOptions = useChartDeferredRevenueConfigOptions();
  const normalizedPickerOptions = useChartMetricsOptions(isComplexCase);

  const getSelectedValue = () => {
    // complex formula
    if (metricSchema.schemaType === 'complex') {
      return '';
    } else if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'group') {
      return `group:${firstNode.data.groupId}`;
    } else if (firstNode?.data?.operator === 'sales' && firstNode?.data?.metricId) {
      const isRenewal = firstNode.data.metricId.startsWith('RENEWALS');
      return `pt:${isRenewal ? 'RENEWALS' : 'NEW_SALES'}`;
    } else if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'pipeline') {
      return `pm:${firstNode.data.pipelineId}`;
    } else if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'stage') {
      return `ps:${firstNode.data.stageId}:${firstNode.data.pipelineId}`;
    }
    // get value from the choices
    else {
      return normalizedPickerOptions.find(option =>
        [
          (firstNode!.data as
            | MetricSchema2DataSourceNativeMetric
            | MetricSchema2DataSourceDerivedMetric
            | MetricSchema2DataSourceInvoicedRevenue).metricId,
          (firstNode!.data as MetricSchema2DataSourceEntityAccount).accountId?.toString(),
        ].includes(option.id.split(':')[1]),
      )?.id;
    }
  };

  const getSelectedPipelineMetricValue = () => {
    if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'pipeline') {
      return firstNode.data.pipelineMetricId;
    }
  };

  const getSelectedPipelineStageMetricValue = () => {
    if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'stage') {
      return firstNode.data.stageMetricId;
    }
  };

  const getSelectedSalesMetricValue = () => {
    if (firstNode?.data?.operator === 'sales' && firstNode?.data?.metricId) {
      return firstNode.data.metricId;
    }
  };
  const getInvoicedRevenueValue = () => {
    if (firstNode?.data?.operator === 'invoiced-revenue' && firstNode?.data.configId) {
      return firstNode.data.configId;
    }
  };

  const handleSeriesPeriodBeforeChange = (before: number, after: number, index: number) => {
    const periods = before * -1 + after + 1;
    const offset = after;
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.map((series, seriesIndex) => {
          if (seriesIndex === index) {
            return {
              ...series,
              periods,
              offset,
            };
          }
          return series;
        }),
      },
    });
  };

  const handleSeriesPeriodAfterChange = (after: number, before: number, index: number) => {
    const periods = before * -1 + after + 1;
    const offset = after;
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.map((series, seriesIndex) => {
          if (seriesIndex === index) {
            return {
              ...series,
              periods,
              offset,
            };
          }
          return series;
        }),
      },
    });
  };
  const handleSeriesType = (value: any, index: number) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.map((series, seriesIndex) => {
          if (seriesIndex === index) {
            if (value === 'Stacked') {
              return {
                ...series,
                type: 'ColumnSeries',
                stacked: true,
              };
            }
            return {
              ...series,
              type: value,
              stacked: false,
            };
          }

          return series;
        }),
      },
    });
  };
  const handleBudgetSet = (value: number | null, index: number) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.map((series, seriesIndex) => {
          if (seriesIndex === index) {
            return {
              ...series,
              budget: organisation.consolidation_type === 'PARENT' ? null : value,
              consolidated_budget: organisation.consolidation_type === 'PARENT' ? value : null,
            };
          }

          return series;
        }),
      },
    });
  };
  const handleValueBasisChange = (value: ValueBasis, index: number) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.map((series, seriesIndex) => {
          if (seriesIndex === index) {
            return {
              ...series,
              value_basis: value,
              consolidated_budget: undefined,
            };
          }

          return series;
        }),
      },
    });
  };
  const handleColorChange = (color: string, index: number) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.map((series, seriesIndex) => {
          if (seriesIndex === index) {
            return {
              ...series,
              color,
            };
          }

          return series;
        }),
      },
    });
  };
  const handleChangeSeriesName = (value: string, index: number) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: [
          ...chart.chart_data.series.slice(0, index),
          {
            ...chart.chart_data.series[index],
            name: value,
          },
          ...chart.chart_data.series.slice(index + 1),
        ],
      },
    });
  };
  const handleChangeSeriesExpand = (value: boolean, index: number) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: [
          ...chart.chart_data.series.slice(0, index),
          {
            ...chart.chart_data.series[index],
            expand: value,
          },
          ...chart.chart_data.series.slice(index + 1),
        ],
      },
    });
  };

  const handleDeleteSeries = (index: number) => {
    if (chart.chart_data.series[index - 1]) {
      setActiveSeries(chart.chart_data.series[index - 1].id);
    } else if (chart.chart_data.series[index + 1]) {
      setActiveSeries(chart.chart_data.series[index + 1].id);
    } else {
      setActiveSeries('');
    }

    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.filter((_, seriesIndex) => seriesIndex !== index),
      },
    });
  };
  const handleAccountSelection = (newValue: string | null) => {
    if (!newValue) {
      return;
    }
    const [type, newId, secondId] = newValue.split(':');
    let newSimpleMetricSchemaDataSource: MetricSchema2DataSource;
    // Pipeline Type
    if (type === 'pt') {
      newSimpleMetricSchemaDataSource = {
        operator: 'sales',
        metricId: `${newId}_IN_PROGRESS_ACV` as SalesMetricIds,
      };
    } else if (type === 'nm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'native',
        metricId: newId,
        isCashflow: false,
      };
    } else if (type === 'dm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'derived',
        metricId: newId,
      };
    } else if (type === 'ir') {
      newSimpleMetricSchemaDataSource = {
        operator: 'invoiced-revenue',
        metricId: newId,
      };
    } else if (type === 'sm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'sales',
        metricId: newId as SalesMetricIds,
      };
    } else if (type === 'pm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'pipeline',
        pipelineId: newId,
        pipelineMetricId: 'metric_deals_won',
      };
    } else if (type === 'ps' && secondId) {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'stage',
        pipelineId: secondId,
        stageId: newId,
        stageMetricId: 'metric_deals',
      };
    } else if (type === 'account') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'account',
        accountId: parseInt(newId),
        isCashflow: false,
      };
    } else if (type === 'group') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'group',
        groupId: parseInt(newId),
        isCashflow: false,
      };
    } else {
      throw new Error('Unknown type');
    }

    const newSimpleMetricSchema: MetricSchema2Simple = {
      schemaType: 'simple',
      nodes: [
        {
          operator: 'add',
          data: newSimpleMetricSchemaDataSource,
        },
      ],
      decimals: 0,
    };

    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.map((series, seriesIndex) => {
          if (seriesIndex === index) {
            return {
              ...series,
              metric_schema: newSimpleMetricSchema,
            };
          }
          return series;
        }),
      },
    });
  };

  const handlePipelineMetricSelection = (newValue: string | null) => {
    if (!newValue || !firstNode) {
      return;
    }
    const seriesCopy = [...chart.chart_data.series];
    // @ts-ignore
    seriesCopy[index].metric_schema.nodes[0].data.pipelineMetricId = newValue as PipelineMetricsTypes;
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: seriesCopy,
      },
    });
  };

  const handleSalesMetricSelection = (salesMetricId: string | null, isRenewal: boolean) => {
    if (!salesMetricId || !firstNode) {
      return;
    }
    const seriesCopy = [...chart.chart_data.series];
    const prefix = isRenewal ? 'RENEWALS' : 'NEW_SALES';
    // @ts-ignore
    seriesCopy[index].metric_schema.nodes[0].data.metricId = `${prefix}_${salesMetricId}` as SalesMetricIds;
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: seriesCopy,
      },
    });
  };
  const handleStageMetricSelection = (newValue: string | null) => {
    if (!newValue || !firstNode) {
      return;
    }
    const seriesCopy = [...chart.chart_data.series];
    // @ts-ignore
    seriesCopy[index].metric_schema.nodes[0].data.stageMetricId = newValue as PipelineStageMetricsTypes;
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: seriesCopy,
      },
    });
  };
  const handleUpdateInvoicedRevenueValue = (newValue: string | null) => {
    if (!newValue || !firstNode) {
      return;
    }
    const seriesCopy = [...chart.chart_data.series];
    // @ts-ignore
    seriesCopy[index].metric_schema.nodes[0].data.configId = newValue;
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: seriesCopy,
      },
    });
  };

  const handleChangeYAxisSide = (onRight: boolean) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: chart.chart_data.series.map((series, seriesIndex) => {
          if (seriesIndex === index) {
            return {
              ...series,
              y_axis_side: onRight ? 'RIGHT' : 'LEFT',
            };
          }
          return series;
        }),
      } as TimeChartData,
    });
  };

  const debounceUpdateSerieName = useMemo(() => debounce(handleChangeSeriesName, 2000), []);
  const chartPeriod =
    chart.chart_data.period === 'MONTH'
      ? 'months'
      : chart.chart_data.period === 'QUARTER' || chart.chart_data.period === 'QUARTER_TO_DATE'
      ? 'quarters'
      : 'years';

  const pickerOptionValue = getSelectedValue();

  const salesMetricValue = getSelectedSalesMetricValue()?.replace('RENEWALS_', '').replace('NEW_SALES_', '');

  return (
    <SeriesOptionContainer
      onClick={() => {
        if (!showDelete) {
          setActiveSeries(series.id);
        }
      }}
      active={activeSeries === series.id}
      id={`series-${series.id}`}
    >
      <DeleteSeriesContainer visibility={showDelete}>
        <Column hAlign="center" spacing="large">
          <Icon name="delete" color={theme.palette.granite} size={6} />
          <Typography size="small" weight="medium" color="black">
            Are you sure you want to delete
          </Typography>
          <Typography size="small" weight="regular" color="black" display="block">
            {series.name}
          </Typography>
          <Row vAlign="center" spacing="medium" onClick={() => setShowDelete(false)}>
            <TextButton variant="secondary" width="110px">
              Cancel
            </TextButton>
            <TextButton variant="danger" width="110px" onClick={() => handleDeleteSeries(index)}>
              Delete
            </TextButton>
          </Row>
        </Column>
      </DeleteSeriesContainer>
      <Row hAlign="space-between" vAlign="center">
        <Typography size="small" color="secondary" weight="medium">
          Series {index + 1}
        </Typography>
      </Row>
      <Row hAlign="space-between" vAlign="center" width="100%">
        {series.expand ? (
          <StyledTooltip
            trigger="hover"
            color="white"
            placement="right"
            content={
              <Row width={theme.sizing(147)}>
                <Typography color="secondary" weight="regular" size="small">
                  This series will be shown in Groups. If you ever want to change the Groups, just go to “Load Data”,
                  “Configure P&L /Balance Sheet”.
                </Typography>
              </Row>
            }
          >
            <StyledDisabledSeriesName vAlign="center" width="100%" spacing="tiny">
              <Icon name="lock" color={theme.palette.granite} size={6} />
              <Typography color="secondary">{series.name}</Typography>
            </StyledDisabledSeriesName>
          </StyledTooltip>
        ) : (
          <StyledSidebarInput
            defaultValue={series.name}
            width="247px"
            onChange={e => debounceUpdateSerieName(e.target.value, index)}
          />
        )}
        <StyledSidebarColorPicker>
          <ColourPicker handleColor={color => handleColorChange(color, index)} color={color} />
        </StyledSidebarColorPicker>
      </Row>

      <Column width="100%">
        <Row hAlign="space-between" vAlign="center" width="100%">
          <Row width="100%">
            <ChartEditorAddRowSelect
              includeFixedNumber={false}
              includeBrackets={false}
              selectWidth={360}
              width={metricSchema.schemaType === 'complex' ? '247px' : '300px'}
              size="medium"
              onChange={handleAccountSelection}
              data={normalizedPickerOptions}
              value={pickerOptionValue!}
              handleFormulaCategorySelected={() =>
                handleConfigure(
                  {
                    schemaType: 'complex',
                    nodes: [],
                    decimals: 0,
                    dataType: 'monetary',
                    impact: 'neutral',
                    aggregation: 'sum',
                  },
                  index,
                )
              }
            />
          </Row>
          {metricSchema.schemaType === 'complex' && (
            <Icon
              clickable
              name="settings"
              size={6}
              color={theme.palette.granite}
              onClick={() => handleConfigure(metricSchema, index)}
            />
          )}
        </Row>

        {normalizedPickerOptions.find(option => option.value === pickerOptionValue) === undefined && (
          <StyledMessage variant="danger" withIcon>
            The selected {isPipeline ? 'pipeline' : isStage ? 'stage' : ''} no longer exists. Please choose a different
            option from the dropdown menu above.
          </StyledMessage>
        )}
      </Column>

      {isSales && !isComplexCase && (
        <Row hAlign="space-between" vAlign="center">
          <Select
            size="medium"
            width="100%"
            placeholder={'Select a sales metric'}
            selectHeight={300}
            selectWidth={338}
            data={pipelineTypeOptions}
            onChange={value =>
              handleSalesMetricSelection(value, !!getSelectedSalesMetricValue()?.startsWith('RENEWALS_'))
            }
            value={salesMetricValue}
          />
        </Row>
      )}
      {isPipeline && !isComplexCase && (
        <Row hAlign="space-between" vAlign="center">
          <Select
            size="medium"
            width="100%"
            placeholder={'Select a pipeline metric'}
            selectHeight={300}
            selectWidth={338}
            data={pipelineMetricOptions}
            onChange={handlePipelineMetricSelection}
            value={getSelectedPipelineMetricValue()}
          />
        </Row>
      )}
      {isStage && !isComplexCase && (
        <Row hAlign="space-between" vAlign="center" width="100%">
          <Select
            size="medium"
            width="100%"
            placeholder={'Select a stage metric'}
            selectHeight={300}
            data={stageMetricOptions}
            onChange={handleStageMetricSelection}
            value={getSelectedPipelineStageMetricValue()}
          />
        </Row>
      )}
      {isInvoicedRevenue && !isComplexCase && (
        <Row hAlign="space-between" vAlign="center" width="100%">
          <Select<DeferredRevenueConfig['id'] | null>
            width="100%"
            size="medium"
            selectWidth={300}
            data={configOptions}
            value={getInvoicedRevenueValue()}
            onChange={value => {
              handleUpdateInvoicedRevenueValue?.(value);
            }}
            placeholder={'Config'}
            onClick={e => e.stopPropagation()}
          />
        </Row>
      )}
      {firstSameSideSeriesIndex > -1 &&
      getSeriesDataType(series) !== getSeriesDataType(chart.chart_data.series[firstSameSideSeriesIndex]) ? (
        <Row width="100%">
          <Message variant="warning" withIcon>
            This series have a different type than series {firstSameSideSeriesIndex + 1}
          </Message>
        </Row>
      ) : null}
      <Row width="100%">
        <Column width="100%" spacing="medium">
          <SwitcherSidebarWrapper>
            <Switcher
              width="100%"
              size="small"
              data={ACTUAL_BUDGET_OPTIONS}
              defaultColor={theme.palette.white}
              activeColor={theme.palette.granite}
              value={valueBasis}
              onChange={value => handleValueBasisChange(value as ValueBasis, index)}
            />
          </SwitcherSidebarWrapper>
          {valueBasis === 'BUDGET' && organisation.consolidation_type === 'PARENT' && (
            <Select
              size="medium"
              selectWidth={300}
              width="100%"
              placeholder="Consolidated budget name"
              data={consolidatedBudgetOptions}
              value={consolidatedBudgetSetId}
              onChange={value => {
                handleBudgetSet(value, index);
              }}
            />
          )}
          {valueBasis === 'BUDGET' && organisation.consolidation_type !== 'PARENT' && (
            <Select
              size="medium"
              selectWidth={300}
              width="100%"
              placeholder="Budget name"
              data={budgetOptions}
              value={budgetSetId}
              onChange={value => {
                handleBudgetSet(value, index);
              }}
            />
          )}
        </Column>
      </Row>
      <Divider />
      <Row width="100%" hAlign="space-between">
        {SERIES_TYPES_OPTIONS.map(chartType => (
          <ChartChoice
            key={chartType.value}
            selected={chartType.value === seriesType}
            onClick={e => handleSeriesType(chartType.value, index)}
          >
            <img src={chartType.img} alt={`chart-${chartType.label}`} />
          </ChartChoice>
        ))}
      </Row>
      <SpacedRow width="100%" hAlign="space-between" vAlign="center">
        <Typography color="secondary" size="medium" weight="regular">
          Show details
        </Typography>
        <Toggle checked={series.expand} onChange={value => handleChangeSeriesExpand(value, index)} />
      </SpacedRow>

      <Divider />
      <Column width="100%" spacing="tiny">
        <Typography size="medium" color="secondary" weight="regular">
          Horizontal Axis (Time period)
        </Typography>
        <Row width="100%" hAlign="flex-start" spacing="xlarge">
          <Column spacing="small">
            <Typography color="secondary" size="small" weight="medium">
              Past {chartPeriod}
            </Typography>
            <Select
              selectHeight={140}
              size="medium"
              placeholder="Before"
              data={PAST_MONTH_OPTIONS(period, 48)}
              onChange={value => handleSeriesPeriodBeforeChange(value, after, index)}
              value={before}
            />
          </Column>
          <Column spacing="small">
            <Typography color="secondary" size="small" weight="medium">
              Future {chartPeriod}
            </Typography>
            <Select
              selectHeight={140}
              size="medium"
              placeholder="After"
              data={FUTURE_MONTH_OPTIONS(period, 48)}
              onChange={value => handleSeriesPeriodAfterChange(value, before, index)}
              value={after}
            />
          </Column>
        </Row>
      </Column>
      <Row width="100%" vAlign="center" hAlign="space-between">
        <Typography color="secondary" size="medium" weight="regular">
          Place on right hand axis
        </Typography>
        <Toggle checked={series.y_axis_side === 'RIGHT'} onChange={handleChangeYAxisSide} />
      </Row>
      <Divider />
      <Row width="100%" hAlign="space-between" vAlign="center">
        <Typography color="secondary" size="medium" weight="regular">
          Delete series
        </Typography>
        <Icon clickable name="delete" color={theme.palette.granite} size={6} onClick={() => setShowDelete(true)} />
      </Row>
    </SeriesOptionContainer>
  );
};

export default TimeChartSeries;
