import React, { DragEvent, ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';
import Typography from 'scalexp/components/atoms/Typography';
import Spacer from 'scalexp/components/layout/Spacer';
import styled from 'styled-components';

import colors from '../../../colors';
import Icon from '../../atoms/Icon';

export interface BlurredModalProps {
  showModal: boolean;
  onHide: () => void;
  variant?: 'white' | 'black' | 'grey' | 'yellow';
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  padding?: 'none' | 'regular';
  draggable?: boolean;
  showSideBanner?: boolean;
  showCloseButton?: boolean;
  closeIconDistance?: string;
  footerContent?: ReactNode;
  headerContent?: ReactNode;
  closeOnClickOutside?: boolean;
  isBlurred?: boolean;
  footerColor?: string;
  onCloseButtonClick?: () => void;
}

const MODAL_PADDING = {
  none: 0,
  regular: 8,
};

const StyledOverlay = styled.div<{ isBlurred: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.high} !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(31, 34, 35, 0.3);
  backdrop-filter: ${({ isBlurred }) => (isBlurred ? 'blur(30px)' : 'unset')};
`;

const StyledModal = styled.div<Partial<BlurredModalProps>>`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  padding: ${({ theme, padding }) => theme.spacing(MODAL_PADDING[padding || 'none'])};
  position: relative;
  display: flex;
  z-index: ${({ theme }) => theme.zIndex.highest} !important;
  flex-direction: column;
  max-height: calc(100% - ${({ theme }) => theme.sizing(10)});
  overflow-y: auto;
  background-color: ${({ variant, theme }) =>
    variant === 'white'
      ? theme.palette.white
      : variant === 'grey'
      ? theme.palette.backgroundGrey
      : variant === 'yellow'
      ? '#FDF7ED'
      : theme.palette.midnight};
  border-radius: ${({ theme }) => theme.sizing(4)};
`;

const StyledFooter = styled.div<Partial<BlurredModalProps>>`
  width: 100%;
  padding: 20px;
  margin-top: auto;
  background-color: ${({ theme, color }) => (color ? color : theme.palette.primary.offwhite)};
  border-bottom-left-radius: ${({ theme }) => theme.sizing(2.5)};
  border-bottom-right-radius: ${({ theme }) => theme.sizing(2.5)};
`;

const StyledSideBanner = styled.div`
  height: 100%;
  width: 29px;
  border-radius: ${({ theme }) => `${theme.sizing(2)} 0 0 ${theme.sizing(2)}`};
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledCloseButton = styled.div<Partial<BlurredModalProps>>`
  height: 45px;
  width: 45px;
  position: absolute;
  top: ${({ closeIconDistance }) => (closeIconDistance ? closeIconDistance : '30px')};
  right: ${({ closeIconDistance }) => (closeIconDistance ? closeIconDistance : '30px')};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  z-index: ${({ theme }) => theme.zIndex.highest};
  background-color: ${({ variant }) => (variant === 'white' || variant === 'grey' ? 'unset' : '#000000')};
  cursor: pointer;
`;

type Position = { x: number; y: number };

const BlurredModal: React.FC<React.PropsWithChildren<BlurredModalProps>> = ({
  showModal,
  onHide,
  draggable = false,
  variant = 'grey',
  width = '926px',
  maxWidth,
  minWidth,
  padding = 'none',
  showSideBanner = false,
  closeOnClickOutside = true,
  showCloseButton = true,
  closeIconDistance,
  children,
  footerContent,
  footerColor,
  headerContent,
  isBlurred = true,
  onCloseButtonClick,
}) => {
  const dragItemRef = useRef<HTMLDivElement>(null);
  const dragItemStartPosition = useRef<Position>();
  if (!showModal) {
    return null;
  }

  const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
    const target = dragItemRef.current;
    if (!target) {
      return;
    }

    if (!target.style.left) {
      target.style.left = '0';
      target.style.top = '0';
    }

    dragItemStartPosition.current = {
      x: e.screenX - parseFloat(target.style.left),
      y: e.screenY - parseFloat(target.style.top),
    };
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!dragItemRef.current || !dragItemStartPosition.current) {
      return;
    }

    dragItemRef.current.style.left = `${e.screenX - dragItemStartPosition.current.x}px`;
    dragItemRef.current.style.top = `${e.screenY - dragItemStartPosition.current.y}px`;
  };

  const handleHide: VoidFunction | undefined = closeOnClickOutside ? () => onHide() : undefined;

  return createPortal(
    <StyledOverlay onClick={handleHide} isBlurred={isBlurred} onDragOver={handleDragOver}>
      <StyledModal
        padding={padding}
        draggable={draggable}
        onDragStart={handleDragStart}
        ref={dragItemRef}
        variant={variant}
        width={width}
        maxWidth={maxWidth}
        minWidth={minWidth}
        showSideBanner={showSideBanner}
        onClick={e => e.stopPropagation()}
      >
        {showSideBanner && <StyledSideBanner />}
        {!!headerContent && (
          <>
            <Typography size="h5" weight="regular">
              {headerContent}
            </Typography>
            <Spacer margin={{ bottom: 5 }} />
          </>
        )}
        {children}
        {footerContent && <StyledFooter color={footerColor}>{footerContent}</StyledFooter>}
        {showCloseButton && (
          <StyledCloseButton
            closeIconDistance={closeIconDistance}
            variant={variant}
            onClick={() => {
              onCloseButtonClick?.();
              onHide();
            }}
          >
            <Icon name="close" size={7.5} marginRight={0} color={colors.fieldGrey} />
          </StyledCloseButton>
        )}
      </StyledModal>
    </StyledOverlay>,
    document.body,
  );
};

export default BlurredModal;
