import dayjs from 'dayjs';
import { InvoiceSourceFilter } from 'scalexp/features/deferred-revenue/types';

import { promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectDeferredSeriesRemainingByCustomer } from './selectors';
import { DeferredSeriesRemainingByCustomer } from './types';

export const ORGANISATIONS_DEFERRED_SERIES_REMAINING_BY_CUSTOMER_LOAD = '[deferredSeriesRemainingByCustomer] LOAD';

export type DeferredSeriesRemainingByCustomerActions = PromiseAction<
  typeof ORGANISATIONS_DEFERRED_SERIES_REMAINING_BY_CUSTOMER_LOAD,
  DeferredSeriesRemainingByCustomer,
  { organisationId: number }
>;

type DeferredDataResponse = DeferredSeriesRemainingByCustomer;

export const loadDeferredSeriesRemainingByCustomerPageAction = (
  organisationId: number,
  fromDate: Date,
  toDate: Date,
  accountsFilter: string[],
  invoiceSourceFilter: InvoiceSourceFilter[],
): ThunkAction<DeferredSeriesRemainingByCustomerActions> => async (
  dispatch: ThunkDispatch<DeferredSeriesRemainingByCustomerActions>,
  getState,
) => {
  const state = getState();
  const deferredSeriesRemainingByCustomer = selectDeferredSeriesRemainingByCustomer(state, organisationId);

  if (deferredSeriesRemainingByCustomer?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_DEFERRED_SERIES_REMAINING_BY_CUSTOMER_LOAD,
        () =>
          http<DeferredSeriesRemainingByCustomer[], DeferredDataResponse>(
            `/api/v1/organisations/${organisationId}/invoices/deferred/remaining_by_customer`,
            {
              body: {
                start: dayjs(fromDate).format('YYYY-MM'),
                end: dayjs(toDate).format('YYYY-MM'),
                account_ids: accountsFilter,
                include_issued: true,
                include_pending: false,
                include_renewals: false,
                invoice_source_filter: invoiceSourceFilter,
              },
            },
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
