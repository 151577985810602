/**
 * Consider if you're not bypassing the theme palette by using these colors directly.
 * These are just here for exception cases that aren't part of the branding such as colors for graphs
 */
const colors = {
  midnight: '#030B23',
  white: '#FFFFFF',
  granite: '#4B5D78',
  silver: '#E9EEFB',
  backgroundGrey: '#F8F9FC',
  sky: '#47A7FF',
  cyan: '#A0D2FF',
  fieldGrey: '#ACBAE0',
  secondaryFieldGrey: '#A3BCE8',
  midnightGradient: 'linear-gradient(89.94deg, #181818 0.04%, #051133 0.05%, #030B23 100%, #030B23 100%);',
  blue: {
    50: '#D1DEFC',
    200: '#8DAAF9',
    400: '#4778F6',
    600: '#0044F2',
    800: '#002D9F',
  },
  green: {
    50: '#E3FFDC',
    200: '#C7FFBA',
    400: '#95F47E',
    600: '#53D036',
    800: '#28AD09',
  },
  yellow: {
    50: '#FFEAD0',
    200: '#FFD9AC',
    400: '#FFC37B',
    600: '#FFB256',
    800: '#E17715',
  },
  red: {
    50: '#FFE6E2',
    200: '#FFC7BF',
    400: '#FF9587',
    600: '#FF725F',
    800: '#F24831',
  },
} as const;

export default colors;
