import React, { FC } from 'react';
import TextButton from 'scalexp/components/atoms/TextButton';
import Typography from 'scalexp/components/atoms/Typography';
import Column from 'scalexp/components/layout/Column';
import Spacer from 'scalexp/components/layout/Spacer';
import BlurredModal from 'scalexp/components/molecules/BlurredModal';
import Form from 'scalexp/components/molecules/Form';
import FormFieldSelect from 'scalexp/components/molecules/FormFieldSelect';
import LabeledFormField from 'scalexp/components/molecules/LabeledFormFieldInput';
import { SelectDataItem } from 'scalexp/components/molecules/Select';
import { StyledAccent } from 'scalexp/features/connections/cards/ZohoBooksConnectionCard/RegionSelectionModal.styles';
import { object, SchemaOf, string } from 'yup';

export type RegionSelectValues = {
  region: string;
};

const REGIONS: SelectDataItem[] = [
  { label: 'United States (.com)', value: 'us' },
  { label: 'Europe (.eu)', value: 'eu' },
  { label: 'India (.in)', value: 'in' },
  { label: 'Australia (.com.au)', value: 'au' },
  { label: 'Japan (.jp)', value: 'jp' },
  { label: 'Canada (.ca)', value: 'ca' },
  { label: 'China (.com.cn)', value: 'cn' },
  { label: 'Saudi Arabia (.sa)', value: 'sa' },
];

const regionSelectSchema: SchemaOf<RegionSelectValues> = object({
  region: string().required('Region is required'),
});

type RegionSelectionModalProps = {
  showModal: boolean;
  onSubmit: (values: RegionSelectValues) => void;
  closeModal: () => void;
};

const RegionSelectionModal: FC<RegionSelectionModalProps> = ({ showModal, closeModal, onSubmit }) => {
  return (
    <BlurredModal
      variant="white"
      showModal={showModal}
      onHide={closeModal}
      padding="regular"
      headerContent="Select Zoho Books Region"
    >
      <Form<RegionSelectValues>
        reValidateMode="onChange"
        onSubmit={onSubmit}
        schema={regionSelectSchema}
        actions={
          <TextButton type="submit" iconRight="arrow_right" size="large">
            Continue
          </TextButton>
        }
      >
        <Column spacing="small" width="100%">
          <Typography>
            There are different regions for Zoho Books, and you will have to choose the one that is applicable to you.
          </Typography>
          <Typography>
            <b>Note:</b> To know the region you're accessing Zoho Books from, visit the Zoho Books web app and check its
            URL. If the URL contains books.zoho
            <StyledAccent>.com</StyledAccent>, then you're accessing it from the <b>United States (.com)</b> region. If
            the URL contains books.zoho
            <StyledAccent>.in</StyledAccent>, you're accessing it from the <b>India (.in)</b> region. Similarly, you
            could be accessing Zoho Books from the <b>Europe (.eu)</b> or <b>Australia (.com.au)</b> or other region.
          </Typography>
          <LabeledFormField label="Region" customSize="large" width="100%">
            <FormFieldSelect placeholder="Please select" data={REGIONS} name="region" />
          </LabeledFormField>
          <Spacer margin={{ bottom: 4 }} />
        </Column>
      </Form>
    </BlurredModal>
  );
};

export default RegionSelectionModal;
