import { Connection } from 'scalexp/components/organisms/OAuthConnectionsManager';

const CONNECTION_NAMES = ['xero', 'quickbooks', 'stripe', 'zohobooks', 'pipedrive', 'salesforce', 'hubspot'] as const;

export type ConnectionName = typeof CONNECTION_NAMES[number];

export type ConnectionError = {
  status: 'error';
  error: any;
};

export type ConnectionNotConnected = {
  status: 'notconnected';
};

export type ConnectionSuccess = {
  status: 'connected';
} & Connection;

export type ConnectionNeedsRelink = {
  status: 'needs-relink';
};

export interface ConsolidatedConnection {
  organisation_id: number;
  organisation_name: string;
  connections: Connection[];
}

export interface ConsolidatedMappedConnection {
  organisation_id: number;
  organisation_name: string;
  connections: ConnectionStatuses;
}

export type ConnectionStatus = ConnectionError | ConnectionNotConnected | ConnectionNeedsRelink | ConnectionSuccess;

export type ConnectionStatuses = { [connectionId: string]: ConnectionStatus };

export const INITIAL_CONNECTIONS: ConnectionStatuses = Object.fromEntries<ConnectionStatus>(
  CONNECTION_NAMES.map(name => [name, { status: 'notconnected' }]),
);
