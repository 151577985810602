import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useState, useRef, useCallback, useLayoutEffect, HTMLAttributes, useEffect } from 'react';
import { DATE_FORMAT_DISPLAY } from 'scalexp/components/molecules/DateSelect/dateSupport';
import styled from 'styled-components';

import Icon from '../../../components/atoms/Icon';
import Typography from '../../../components/atoms/Typography';
import { useDateSelectionDate } from '../../../components/molecules/DateSelect/useDateSelection';
import Tooltip from '../../../components/molecules/Tooltip';
import {
  useEditedReport,
  useEditReportSelectedRowId,
  useSetEditedReportSelectedRowId,
  useUpdateEditedReport,
} from '../../../store/state/editedReport/hooks';
import { Report } from '../../../store/state/reports/types';
import { theme } from '../../../theme';
import ReportEditorAddRow from '../ReportEditorAddRow';
import ReportEditorRow from '../rows/ReportEditorRow';

interface DragHanldeProps extends HTMLAttributes<HTMLDataElement> {
  tooltipText?: string;
}

export const DragHandle: React.FC<DragHanldeProps> = ({ tooltipText, ...props }) =>
  tooltipText ? (
    <Tooltip content={tooltipText}>
      <Icon name="drag_indicator" rounded color={theme.palette.granite} size={5} marginRight={0} clickable {...props} />
    </Tooltip>
  ) : (
    <Icon name="drag_indicator" rounded color={theme.palette.granite} size={5} marginRight={0} clickable {...props} />
  );

interface ReportEditorContentProps {
  report: Report;
}

const StyledExpandableContainer = styled.div<{ showDropDown: boolean }>`
  height: ${({ showDropDown }) => `calc(100% + ${showDropDown ? 300 : 0}px)`};
  overflow-y: auto;
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
`;

const StyledContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const StyledHeaderContainer = styled.div`
  width: 80%;
  height: 42px;
  position: sticky;
  top: 0;
  margin: auto;
  z-index: 10;
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
`;

const StyledHeader = styled.div`
  width: 100%;
  height: 46px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 160px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding-left: ${({ theme }) => theme.spacing(10)};
  padding-right: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => `${theme.sizing(2.5)} ${theme.sizing(2.5)}`} 0 0;
  background: linear-gradient(89.94deg, #181818 0.04%, #051133 0.05%, #030b23 100%, #030b23 100%);
  text-align: end;
`;

const StyledContent = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2.5)};
  padding-top: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  margin: auto;
`;

const ReportEditorContent: React.FC<React.PropsWithChildren<ReportEditorContentProps>> = ({ report }) => {
  const date = useDateSelectionDate();

  const editedReport = useEditedReport();
  const selectedRowId = useEditReportSelectedRowId();
  const setSelectedRowId = useSetEditedReportSelectedRowId();
  const updateEditedReport = useUpdateEditedReport();
  const expandableContainerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [showAddRowDropDown, setShowAddRowDropDown] = useState(false);
  const rowSelectElement = expandableContainerRef.current?.lastElementChild?.lastElementChild;
  const isRowSelectElementNearBottom =
    rowSelectElement && rowSelectElement?.getBoundingClientRect().bottom > window.innerHeight - 300;
  // scroll when the drop down shown
  useLayoutEffect(() => {
    // If rowSelectElement is positioned near the bottom
    // of the viewport by 300 pxs or more, scroll to the bottom
    if (showAddRowDropDown && rowSelectElement && editedReport?.rows && isRowSelectElementNearBottom) {
      expandableContainerRef.current?.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }
  }, [expandableContainerRef.current, showAddRowDropDown]);

  useEffect(() => {
    if (report.rows.length > 0) {
      setSelectedRowId(report.rows[0].id);
      setShowAddRowDropDown(false);
    } else {
      setShowAddRowDropDown(true);
    }
  }, [report.id]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    let newRows = [...report.rows];

    if (over && active.id !== over?.id) {
      const oldIndex = newRows.findIndex(row => row.id === active.id);
      const newIndex = newRows.findIndex(row => row.id === over?.id);

      newRows = arrayMove(newRows, oldIndex, newIndex);
    }
    updateEditedReport({
      ...report,
      rows: newRows,
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [scrollRef.current]);
  return (
    <StyledExpandableContainer
      showDropDown={showAddRowDropDown && !!editedReport?.rows && !!isRowSelectElementNearBottom}
      ref={expandableContainerRef}
    >
      <StyledContainer ref={scrollRef}>
        <StyledHeaderContainer>
          <StyledHeader>
            <div />
            <Typography color="white">{date.format(DATE_FORMAT_DISPLAY)}</Typography>
          </StyledHeader>
        </StyledHeaderContainer>
        <StyledContent>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={report.rows} strategy={verticalListSortingStrategy}>
              {report.rows.map(row => {
                const isSelected = row.id === selectedRowId;

                const handleIsSelectedChange = (isSelected: boolean) => {
                  setSelectedRowId(isSelected ? row.id : null);
                };

                return (
                  <ReportEditorRow
                    key={row.id}
                    row={row}
                    isSelected={isSelected}
                    handleIsSelectedChange={handleIsSelectedChange}
                  />
                );
              })}
            </SortableContext>
          </DndContext>
        </StyledContent>
        <ReportEditorAddRow
          showDropDown={showAddRowDropDown}
          setShowDropDown={setShowAddRowDropDown}
          scrollToBottom={scrollToBottom}
        />
      </StyledContainer>
    </StyledExpandableContainer>
  );
};

export default ReportEditorContent;
