import useSWR from 'swr';

export type UserProfile = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  job_title: string;
  date_format: 'DMY' | 'MDY' | 'YMD';
  is_xero_connection: boolean;
  has_password: boolean;
};

const useUserSettings = () => {
  return useSWR<UserProfile>('/userconfig/settings/profile');
};

export default useUserSettings;
