import React from 'react';
import { useHistory } from 'react-router-dom';
import useActiveOrganisationId from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisationId';
import fetcher from 'scalexp/utils/fetcher';
import launchPopup from 'scalexp/utils/launchPopup';
import { mutate } from 'swr';

import useConnection from '../../ConnectionsContext/useConnection';
import ConnectionCard, { ConnectionCardProps } from '../ConnectionCard';

type AdditionalConnectionParams = Record<string, string> | null;

export type ChildConnectionCardProps = {
  hide: 'connected' | 'notconnected';
  isOnboarding?: boolean;
  selectedOrganisationId?: number;
  getAdditionalConnectionParams?: () => Promise<AdditionalConnectionParams>;
};

type BasicConnectionCardProps = Optional<ConnectionCardProps, 'handleConnect'> & ChildConnectionCardProps;

const BasicConnectionCard: React.FC<React.PropsWithChildren<BasicConnectionCardProps>> = ({
  hide,
  isOnboarding,
  selectedOrganisationId,
  getAdditionalConnectionParams,
  name: connectionName,
  ...rest
}) => {
  const history = useHistory();

  const connection = useConnection(connectionName);
  let organisationId = useActiveOrganisationId();
  if (selectedOrganisationId) {
    // If we are onboarding, we want to use the selected organisation id
    organisationId = selectedOrganisationId;
  }

  const handleConnect = async () => {
    const connectUrl = `/oauth/connect-to-${connectionName}`;
    const urlParams = new URLSearchParams();
    if (selectedOrganisationId) {
      urlParams.append('subsidiary_id', selectedOrganisationId.toString());
    }
    if (getAdditionalConnectionParams) {
      const additionalParams = await getAdditionalConnectionParams();
      if (!additionalParams) {
        return;
      }
      Object.entries(additionalParams).forEach(([key, value]) => {
        urlParams.append(key, value);
      });
    }
    if (isOnboarding) {
      urlParams.append('is_onboarding', 'true');
    }
    launchPopup(`${connectUrl}?${urlParams}`);
  };

  const handleConfigure = async () => {
    history.push(`/oauth/${connectionName}`);
  };

  if (connection.status === hide) {
    return null;
  }
  const handleDisconnect = async () => {
    const result = await fetcher(`/api/v1/connections/${connectionName}/disconnect`, {
      method: 'POST',
    });
    await mutate(`/api/v1/${organisationId}/connections`);
    return result;
  };

  return (
    <ConnectionCard
      {...rest}
      name={connectionName}
      handleConnect={handleConnect}
      handleConfigure={handleConfigure}
      handleDisconnect={handleDisconnect}
      error={connection.status === 'error' && connection.error}
    />
  );
};

export default BasicConnectionCard;
