import dayjs from 'dayjs';
import React, { useState, useRef, useMemo, useCallback, useEffect, ReactNode } from 'react';
import colors from 'scalexp/colors';
import Icon from 'scalexp/components/atoms/Icon';
import IconButton from 'scalexp/components/atoms/IconButton';
import ImageButton from 'scalexp/components/atoms/ImageButton';
import TextButton from 'scalexp/components/atoms/TextButton';
import Typography from 'scalexp/components/atoms/Typography';
import useActiveOrganisation from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisation';
import Aligner from 'scalexp/components/layout/Aligner';
import Column from 'scalexp/components/layout/Column';
import { useOutsideAlerter } from 'scalexp/components/molecules/MultiSelect/hooks';
import { ConnectionName } from 'scalexp/features/connections/ConnectionsContext/types';
import { formatConnectionName } from 'scalexp/features/connections/ConnectionsContext/useGetAccountingAndSalesSystem';
import { useNotAcknowledgedAccounts } from 'scalexp/store/state/accounts/hooks';
import styled from 'styled-components';

import { useConnectionsContext } from '../../ConnectionsContext';
import useConnection from '../../ConnectionsContext/useConnection';
import { useSyncContext } from '../../sync-context/SyncContext';
import useSync from './useSync';

export type ConnectionCardProps = {
  name: ConnectionName;
  logo: string;
  notification?: ReactNode;
  connectImg?: string;
  connectImgHover?: string;
  error?: any;
  handleConnect: () => void;
  handleDisconnect?: () => void;
  handleConfigure?: () => void;
  showLastSynced?: boolean;
  locked?: boolean;
};

const StyledCard = styled.div`
  width: 218px;
  min-height: 212px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.spacing(1)};
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.05);
`;

const StyledImageContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1)};

  & > img {
    border-radius: ${({ theme }) => theme.spacing(1)};
    width: 100%;
  }
`;

const StyledContent = styled.div`
  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(2)} ${theme.spacing(2)}`};
`;

const StyledStatusContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(2)} ${theme.spacing(4)}`};
`;

const StyledStatus = styled.div<{ status: 'connected' | 'needs-relink' | 'warning' }>`
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ status, theme }) => {
    switch (status) {
      case 'connected':
        return theme.palette.success.offwhite;
      case 'warning':
        return '#FFEFDF';
      case 'needs-relink':
        return theme.palette.danger.offwhite;
      default:
        return '';
    }
  }};
  border-radius: 50%;

  &::after {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${({ status, theme }) => {
      switch (status) {
        case 'connected':
          return theme.palette.success.lighter;
        case 'warning':
          return '#BD5B00';
        case 'needs-relink':
          return theme.palette.danger.light;
        default:
          return '';
      }
    }};
  }
`;

const StyledActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};

  & button:first-child {
    flex: 1;
  }
`;

const StyledMenu = styled.div`
  width: 218px;
  position: absolute;
  top: 216px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  z-index: ${({ theme }) => theme.zIndex.regular};
  background-color: ${({ theme }) => theme.palette.white};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  border: 1px solid ${({ theme }) => theme.palette.silver};
  border-radius: ${({ theme }) => theme.sizing(1)};
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.05);

  & > div {
    cursor: pointer;
  }
`;

const ConnectionCard: React.FC<React.PropsWithChildren<ConnectionCardProps>> = ({
  name,
  logo,
  connectImg,
  connectImgHover,
  handleConnect,
  handleDisconnect,
  handleConfigure,
  error,
  notification,
  showLastSynced = true,
  locked = false,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const displayName = formatConnectionName(name);

  const [syncing, setSyncing] = useState<ConnectionName[]>([]);

  const [showMenu, setShowMenu] = useState(false);
  useOutsideAlerter(menuRef, () => setShowMenu(false));

  const { organisation_id } = useActiveOrganisation();
  const connection = useConnection(name);
  const { revalidate } = useConnectionsContext();
  const notAcknowledgedAccounts = useNotAcknowledgedAccounts(organisation_id);
  const { jobs } = useSyncContext();

  useEffect(() => {
    if (jobs.findIndex(job => job.status === 'Running' && job.name === name) >= 0 && !syncing.includes(name)) {
      setSyncing(prev => [...prev, name]);
    } else if (jobs.findIndex(job => job.status === 'Running' && job.name === name) < 0) {
      setSyncing(prev => prev.filter(nameC => name !== nameC));
    }
  }, [jobs]);
  const hasWarnings = useMemo(
    () =>
      notAcknowledgedAccounts.status === 'success'
        ? !!Object.values(notAcknowledgedAccounts.value).find(account => account.source_name === name)
        : false,
    [notAcknowledgedAccounts],
  );

  const handleSync = useSync();

  if (!organisation_id) {
    throw Error('organisation_id not found!');
  }

  // TODO: handle errors
  if (error) {
    console.log('TODO: Unhandled error!', error);
  }

  const handleSyncNow = async () => {
    setSyncing([...syncing, name]);
    await handleSync(name);

    setSyncing([...syncing.filter(item => item !== name)]);
  };

  const handleDisconnectAndReload = useCallback(() => {
    handleDisconnect!();
    revalidate();
  }, [handleDisconnect]);

  const isConnected = connection.status === 'connected';
  const notConnected = connection.status === 'notconnected';
  const needsRelink =
    connection.status === 'needs-relink' || (connection.status === 'connected' && connection.needs_relinking);
  const neverImported = isConnected && !connection.last_completed_import;

  if (locked) {
    return (
      <StyledCard>
        <StyledImageContainer>
          <img src={logo} alt="connections-card" />
        </StyledImageContainer>
        <StyledContent>
          <StyledStatusContainer>
            <Column hAlign="flex-start" spacing="tiny">
              <Typography size="medium" weight="bold">
                {displayName}
              </Typography>
              <Icon name="lock" />
              <Typography size="tiny" weight="semibold">
                Can not sync this connection during a free trial. Please contact Customer Success if you have questions:
                <a href="mailto:support@scalexp.com">Support@ScaleXP.com</a>
              </Typography>
            </Column>
          </StyledStatusContainer>
        </StyledContent>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <StyledImageContainer>
        <img src={logo} alt="connections-card" />
      </StyledImageContainer>
      <StyledContent>
        <StyledStatusContainer>
          <Aligner direction="vertical" hAlign="flex-start" gap="4px">
            <Aligner gap="4px">
              <Typography size="medium" weight="bold">
                {displayName}
              </Typography>
              {!notConnected && connection.status !== 'error' && (
                <StyledStatus status={needsRelink ? 'needs-relink' : hasWarnings ? 'warning' : 'connected'} />
              )}
            </Aligner>

            {!!notification && notification}

            {showLastSynced && isConnected && connection.last_completed_import && (
              <Typography size="tiny" color="secondary">
                Last synced: {dayjs().to(connection.last_completed_import.import_date)}
              </Typography>
            )}
            {neverImported && (
              <Typography size="tiny" color="secondary">
                Not imported yet
              </Typography>
            )}
            {notConnected && (
              <Typography size="tiny" color="secondary">
                Not connected
              </Typography>
            )}
          </Aligner>
        </StyledStatusContainer>
        <StyledActions>
          {needsRelink && name !== 'quickbooks' ? (
            <TextButton variant="danger-outlined" onClick={handleConnect}>
              Relink
            </TextButton>
          ) : (
            isConnected && (
              <>
                {(neverImported && !['salesforce'].includes(name)) || name === 'stripe' ? (
                  <TextButton variant="secondary" onClick={handleConfigure} disabled={syncing.includes(name)}>
                    <Aligner hAlign="flex-start" gap="12px">
                      <Icon name="settings" size={4} marginRight={0} />
                      <Typography>Configure</Typography>
                    </Aligner>
                  </TextButton>
                ) : (
                  <>
                    <TextButton
                      variant="secondary"
                      iconLeft="refresh"
                      onClick={handleSyncNow}
                      disabled={syncing.includes(name)}
                    >
                      {syncing.includes(name) ? 'Syncing...' : 'Sync now'}
                    </TextButton>
                    <IconButton name="more_horiz" tooltipText="More" onClick={() => setShowMenu(true)} />
                  </>
                )}
              </>
            )
          )}

          {(notConnected || needsRelink) &&
            (connectImg ? (
              <ImageButton src={connectImg} hoverSrc={connectImgHover} onClick={handleConnect} />
            ) : (
              <TextButton variant="outlined" onClick={handleConnect}>
                Connect
              </TextButton>
            ))}
        </StyledActions>
      </StyledContent>
      {showMenu && isConnected && (
        <StyledMenu ref={menuRef}>
          {handleConfigure && (
            <Aligner hAlign="flex-start" gap="12px" onClick={handleConfigure}>
              <Icon name="settings" size={4} color={colors.granite} marginRight={0} />
              <Typography color="secondary">Configure</Typography>
            </Aligner>
          )}
          {handleDisconnect && (
            <Aligner hAlign="flex-start" gap="12px" onClick={handleDisconnectAndReload}>
              <Icon name="link_off" size={4} color={colors.granite} marginRight={0} />
              <Typography color="secondary">Disconnect</Typography>
            </Aligner>
          )}
        </StyledMenu>
      )}
    </StyledCard>
  );
};

export default ConnectionCard;
