import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useUserContext } from 'scalexp/components/contexts/UserContext';
import useGetAccountingAndSalesSystem, {
  isConnectionName,
} from 'scalexp/features/connections/ConnectionsContext/useGetAccountingAndSalesSystem';
import OnboardingChatSupport from 'scalexp/features/onboarding/OnboardingChatSupport';
import SyncStatusBarContainer from 'scalexp/features/sync-status-bar/sync-status-bar-container/SyncStatusBarContainer';
import styled from 'styled-components';

import useActiveOrganisation from '../../contexts/OrganisationContext/useActiveOrganisation';
import { useTrialInfoContext } from '../../contexts/TrialContext';
import TrialBanner from '../../molecules/TrialBanner';

const StyledContainer = styled.div``;

const StyledGlobalFixedElementContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  z-index: ${({ theme }) => theme.zIndex.high};
`;

const GlobalFixedElementContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation();
  const history = useHistory();
  const organisation = useActiveOrganisation();
  const { accountingSystem, isLoading } = useGetAccountingAndSalesSystem();
  const user = useUserContext();
  const { trialInfo } = useTrialInfoContext();

  // @ts-ignore
  window.initializeUserGuiding();
  // @ts-ignore
  window.userGuiding.identify(user?.user_id, {
    Account_status: trialInfo?.status,
    Organisation_selection: organisation.consolidation_type,
  });

  let pageStringify = '';
  let page = location.pathname.slice(1);
  if (page.startsWith('oauth')) {
    pageStringify = page
      .split('/')
      .filter(str => str !== '')
      .join('-');
  }

  useEffect(() => {
    if (
      !accountingSystem &&
      pageStringify !== 'oauth' &&
      !isConnectionName(pageStringify.split('oauth-')[1]) &&
      !isLoading &&
      organisation.children.length === 0
    ) {
      history.push('/oauth');
    }
  }, [isLoading, location.pathname]);

  return (
    <StyledContainer>
      <OnboardingChatSupport />
      {
        <StyledGlobalFixedElementContainer>
          <SyncStatusBarContainer />
          {trialInfo?.status === 'trial' && trialInfo.num_of_trial_days_remaining <= 10 && (
            <TrialBanner status={trialInfo.status} daysRemaining={trialInfo.num_of_trial_days_remaining} />
          )}
        </StyledGlobalFixedElementContainer>
      }
    </StyledContainer>
  );
};

export default GlobalFixedElementContainer;
