import React from 'react';
import { ConnectionStatuses, ConnectionSuccess } from 'scalexp/features/connections/ConnectionsContext/types';
import {
  ACCOUNTING_CONNECTIONS,
  formatConnectionName,
} from 'scalexp/features/connections/ConnectionsContext/useGetAccountingAndSalesSystem';
import { Organisation } from 'scalexp/store/state/organisations/types';
import { theme } from 'scalexp/theme';
import styled from 'styled-components';

import TextButton from '../../atoms/TextButton';
import Typography from '../../atoms/Typography';
import Column from '../../layout/Column';
import Row from '../../layout/Row';
import Card from '../Card';
import { getCurrencyName } from '../CurrencySelect/currencySupport';

interface OnboardingOrganisationCardPropsType {
  organistion: Organisation;
  handleConnect: () => void;
  connections?: ConnectionStatuses;
}
const OnBoardingOrganisationCard = styled(Card)`
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.05);
`;
const StyledStatus = styled.div`
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.palette.success.offwhite};
  border-radius: 50%;

  &::after {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${theme.palette.success.lighter};
  }
`;

const AccountingSystemBanner = styled.div`
  width: 180px;
  height: 80px;
  border-radius: 8px;
  position: relative;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: inherit;
  }
`;
const OnboardingOrganisationCard: React.FC<OnboardingOrganisationCardPropsType> = ({
  organistion,
  handleConnect,
  connections,
}) => {
  const connectedConnection = ACCOUNTING_CONNECTIONS.reduce<ConnectionSuccess | null>((acc, connection_name) => {
    if (acc) {
      return acc;
    }
    const connection = connections?.[connection_name];
    return connection?.status === 'connected' ? connection : null;
  }, null);

  return (
    <OnBoardingOrganisationCard width="902px" height="114px">
      <Row width="100%" vAlign="center" hAlign="space-between">
        <Column spacing="tiny">
          <Typography size="h4" weight="semibold">
            {organistion.name} {organistion.name_short}
          </Typography>
          <Typography size="medium" weight="regular">
            {getCurrencyName(organistion.default_currency_code)}
          </Typography>
        </Column>
        {connectedConnection ? (
          <Row vAlign="center" spacing="xxlarge">
            <AccountingSystemBanner>
              <img
                src={`/images/logos/${connectedConnection.source_name}-banner.png`}
                alt={`${connectedConnection.source_name} logo`}
              />
            </AccountingSystemBanner>
            <Column spacing="tiny">
              <Typography size="h5" weight="regular">
                {formatConnectionName(connectedConnection.source_name)}
              </Typography>
              <Row vAlign="center" spacing="tiny">
                <StyledStatus />
                <Typography size="medium" color="secondary">
                  Connected
                </Typography>
              </Row>
            </Column>
            <Column hAlign="center" spacing="tiny">
              <Typography size="medium" weight="medium" color="secondary">
                Data imported
              </Typography>
            </Column>
          </Row>
        ) : (
          <TextButton variant="secondary" size="xlarge" width="179px" iconRight="arrow_forward" onClick={handleConnect}>
            Connect
          </TextButton>
        )}
      </Row>
    </OnBoardingOrganisationCard>
  );
};

export default OnboardingOrganisationCard;
