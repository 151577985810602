import dayjs from 'dayjs';
import React, { ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTrialInfoContext } from 'scalexp/components/contexts/TrialContext';
import SidebarVideoGuide from 'scalexp/components/molecules/SidebarVideoGuide';
import styled from 'styled-components';

import colors from '../../../colors';
import useConnectionsNeedRelinkCount from '../../../features/connections/ConnectionsContext/useConnectionsNeedRelinkCount';
import { PageName, SidebarSectionHeader } from '../../../pages/reports/styles';
import { useNotAcknowledgedAccountsCount } from '../../../store/state/accounts/hooks';
import Icon from '../../atoms/Icon';
import IconButton from '../../atoms/IconButton';
import SidebarSection from '../../atoms/SidebarSection';
import SidebarWrapper from '../../atoms/SidebarWrapper';
import Typography from '../../atoms/Typography';
import useActiveOrganisation from '../../contexts/OrganisationContext/useActiveOrganisation';
import Aligner from '../../layout/Aligner';
import Row from '../../layout/Row';
import SidebarItem from '../../molecules/SidebarItem';

export const iconLookup: { [sourceId: string]: (size?: number, excel?: boolean) => ReactNode } = {
  // ScaleXP
  scalexp: (size: number = 24) => <img width={size} src={'/images/logos/scalexp-icon.svg'} alt="scalexp" />,
  manual: (size: number = 24, excel?: boolean) =>
    excel ? (
      <img width={size} src={'/images/logos/targets.svg'} alt="manual" />
    ) : (
      <Icon name="keyboard" outlined size={size / 4} />
    ),
  // CRM
  salesforce: (size: number = 24) => <img width={size} src={'/images/logos/salesforce.png'} alt="salesforce" />,
  pipedrive: (size: number = 24) => <img width={size} src={'/images/logos/pipedrive.png'} alt="pipedrive" />,
  hubspot: (size: number = 24) => <img width={size} src={'/images/logos/hubspot.svg'} alt="hubspot" />,
  // Accounting
  xero: (size: number = 24) => <img width={size} src={'/images/logos/xero.svg'} alt="xero" />,
  quickbooks: (size: number = 24) => <img width={size} src={'/images/logos/quickbooks.svg'} alt="quickbooks" />,
  zohobooks: (size: number = 24) => <img width={size} src={'/images/logos/zohobooks.png'} alt="zohobooks" />,
};

export interface OauthSidebarProps {}

const StyledInfoItem = styled.div<{ active: boolean }>`
  ${({ active, theme }) => (active ? `background-color:${theme.palette.white}` : '')};

  & > div {
    padding: ${({ theme }) => theme.spacing(5)};

    .material-icons-round {
      padding-top: ${({ theme }) => theme.spacing(1)};
    }
  }
`;

let OauthSidebar: React.FC<React.PropsWithChildren<OauthSidebarProps>> = () => {
  const location = useLocation();
  const [isTargetsExpanded, setIsTargetsExpanded] = useState(false);

  const organisation = useActiveOrganisation();
  const organisationId = organisation.organisation_id;
  const notAcknowledgedAccountsCount = useNotAcknowledgedAccountsCount(organisationId);
  const connectionsNeedRelinkCount = useConnectionsNeedRelinkCount();
  const hasConnectionsErrors = connectionsNeedRelinkCount > 0;
  const hasAccountGroupingWarnings = notAcknowledgedAccountsCount > 0;
  const previousMonth = dayjs().subtract(1, 'month').format('YYYY-MM');
  const isConsolidatedBudgetsExpanded = location.pathname.startsWith('/oauth/budgetsets');
  const allowConsolidatedDeferredRevenue = !!organisation.enable_consolidated_customers_page;
  const { trialInfo } = useTrialInfoContext();

  const consolidatedOrSingleOnTrial =
    trialInfo && trialInfo.status === 'trial' && organisation.consolidation_type !== 'SUBSIDIARY';

  if (!organisationId) {
    throw Error('OrganisationId not found!');
  }

  // removing the subheader height
  return (
    <SidebarWrapper role="navigation">
      <SidebarSection>
        <SidebarSectionHeader>
          <PageName>Load Data</PageName>
        </SidebarSectionHeader>
        <SidebarItem
          icon={consolidatedOrSingleOnTrial ? 'lock' : 'power'}
          active={location.pathname === '/oauth/'}
          to="/oauth/"
        >
          <Aligner width="100%" hAlign="space-between">
            Connections
            {hasConnectionsErrors && <Icon name="warning" color={colors.red[400]} size={5} />}
          </Aligner>
        </SidebarItem>
        <SidebarItem
          icon="group_work"
          active={location.pathname.startsWith('/oauth/report-account-groupings')}
          to={`/oauth/report-account-groupings?date=${previousMonth}`}
        >
          <Aligner width="100%" hAlign="space-between">
            Configure P&L, BS
            {hasAccountGroupingWarnings && <Icon name="warning_amber" color="#BD5B00" size={5} />}
          </Aligner>
        </SidebarItem>
        <SidebarItem icon="keyboard" active={location.pathname.startsWith('/oauth/manual')} to="/oauth/manual">
          Manual Entry
        </SidebarItem>
      </SidebarSection>
      {organisation.consolidation_type === 'PARENT' && (
        <SidebarSection>
          <StyledInfoItem active={isConsolidatedBudgetsExpanded}>
            <SidebarItem icon="adjust" active={isConsolidatedBudgetsExpanded} to="/oauth/budgetsets">
              <Row vAlign="center" hAlign="space-between" width="100%">
                Budgets & Forecasts
                <Row width="100%" hAlign="end">
                  <IconButton
                    name={isConsolidatedBudgetsExpanded ? 'expand_less' : 'expand_more'}
                    tooltipText={isConsolidatedBudgetsExpanded ? 'Collapse targets' : 'Expand targets'}
                    tooltipPlacement="left"
                  />
                </Row>
              </Row>
            </SidebarItem>
            {isConsolidatedBudgetsExpanded && (
              <Row hAlign="center" spacing="small">
                <Icon name="info" color={colors.fieldGrey} rounded />
                <Typography size="tiny" weight="regular" color="grey">
                  All budgets are loaded in the subsidiaries
                </Typography>
              </Row>
            )}
          </StyledInfoItem>
        </SidebarSection>
      )}
      <SidebarSection>
        {organisation.consolidation_type !== 'PARENT' && (
          <SidebarItem
            icon="adjust"
            active={
              location.pathname.startsWith('/oauth/budgetsets') || location.pathname.startsWith('/oauth/targets/intro')
            }
            to="/oauth/budgetsets"
            onClick={() => setIsTargetsExpanded(!isTargetsExpanded)}
          >
            Budgets & Forecasts
          </SidebarItem>
        )}
        <SidebarItem
          icon="money"
          active={location.pathname.startsWith('/oauth/actuals-fx-rates')}
          to="/oauth/actuals-fx-rates/"
        >
          Actuals FX Rates
        </SidebarItem>
        <SidebarItem
          icon="money"
          active={location.pathname.startsWith('/oauth/budget-fx-rates')}
          to="/oauth/budget-fx-rates/"
        >
          Budget FX Rates
        </SidebarItem>
        {organisation.consolidation_type === 'PARENT' && allowConsolidatedDeferredRevenue && (
          <SidebarItem
            icon="money"
            active={location.pathname.startsWith('/oauth/invoice-fx-rates')}
            to="/oauth/invoice-fx-rates/"
          >
            Invoice FX Rates
          </SidebarItem>
        )}
      </SidebarSection>
      <SidebarSection>
        {organisation.consolidation_type === 'PARENT' && (
          <SidebarItem
            icon="compare_arrows"
            active={location.pathname.startsWith('/oauth/eliminate-accounts/')}
            to="/oauth/eliminate-accounts/"
          >
            Eliminated Accounts
          </SidebarItem>
        )}
        {location.pathname.includes('report-account-groupings') && (
          <SidebarVideoGuide
            link={
              organisation.consolidation_type === 'SINGLE'
                ? 'https://youtu.be/OajhLuJb5TM'
                : 'https://youtu.be/62xk7jsdb_o'
            }
            image="/images/onboarding/intro-video.svg"
          />
        )}
      </SidebarSection>
    </SidebarWrapper>
  );
};

export default OauthSidebar;
