import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { formatConnectionName } from 'scalexp/features/connections/ConnectionsContext/useGetAccountingAndSalesSystem';

import Icon from '../../../components/atoms/Icon';
import Loader from '../../../components/atoms/Loader';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import Row from '../../../components/layout/Row';
import { ImportJob } from '../../connections/sync-context/types';

type Props = {
  job: ImportJob;
  onHide: () => void;
};

const getStepsNumberByProvider = (providerName: string): number => {
  switch (providerName) {
    // Accounting
    case 'xero':
      return 33;
    case 'quickbooks':
      return 40;
    case 'zohobooks':
      return 7;

    // CRM
    case 'hubspot':
      return 14;
    case 'salesforce':
      return 19;
    case 'pipedrive':
      return 17;

    // Payments
    case 'stripe':
      return 7;

    default:
      return 1;
  }
};

const ImportSyncStatusBar: React.FC<React.PropsWithChildren<Props>> = ({
  job: { name, status, task, completedSteps, organisationName },
  onHide,
}) => {
  const location = useLocation();

  const [reloadingIn, setReloadingIn] = useState(10);

  const autoRefresh = !location.pathname.includes('onboarding');

  // we refresh automatically if the user is not in the onboarding
  // and (the crm integration onboarding is done or the user is not in the connection config page)

  const handleFinish = () => {
    if (status === 'Completed') {
      onHide();

      if (autoRefresh) {
        window.location.reload();
      }
    }

    onHide();
  };

  useEffect(() => {
    if (status === 'Completed') {
      setInterval(() => setReloadingIn(prev => prev - 1), 1000);
    }
  }, [status]);

  useEffect(() => {
    if (reloadingIn < 1) {
      handleFinish();
    }
  }, [reloadingIn]);

  const totalSteps = getStepsNumberByProvider(name);
  const displayName = formatConnectionName(name);
  const runningTitle = `Running step ${
    completedSteps > totalSteps ? totalSteps : completedSteps
  } of ${totalSteps} of your data import from ${displayName} on ${organisationName}. ${
    autoRefresh ? 'The page will reload when complete to show the new data.' : ''
  }`;
  const completedTitle = `Your import from ${displayName} on ${organisationName} is complete${
    autoRefresh ? `, Reloading in ${reloadingIn} seconds` : ''
  } `;
  const errorTitle = `Your import from ${displayName} on ${organisationName} failed, Please try again later`;

  return (
    <>
      <Row vAlign="center">
        {status === 'Running' && <Loader />}
        {status === 'Completed' && <Icon name="check_circle" outlined marginRight={0} />}
        {status === 'Error' && <Icon name="cancel" marginRight={0} />}
        <Typography size="tiny">
          {status === 'Running' ? runningTitle : status === 'Completed' ? completedTitle : errorTitle}
        </Typography>
      </Row>

      {autoRefresh && status !== 'Running' && (
        <TextButton variant="secondary" onClick={handleFinish}>
          {status === 'Completed' ? 'See new data' : 'Close'}
        </TextButton>
      )}
    </>
  );
};

export default ImportSyncStatusBar;
