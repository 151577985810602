import React, { InputHTMLAttributes, useEffect } from 'react';
import styled, { css } from 'styled-components';

import Typography from '../Typography';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  customSize?: 'small' | 'medium' | 'large';
  label?: string;
  hasError?: boolean;
  noBorder?: boolean;
  hideNumberArrows?: boolean;
  suffix?: string;
  selectAllOnMount?: boolean;
}

export const StyledInputContainer = styled.div<InputProps>`
  ${({ width }) => (width ? `width: ${width};` : '')};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2.5)};

  ${({ disabled, theme }) => {
    if (disabled) {
      return css`
        & input {
          opacity: 0.4;
          background-color: #f8f9fc;
        }
      `;
    }
  }};

  ${({ suffix, theme }) => {
    if (suffix) {
      return css`
        & input {
          padding-right: ${theme.spacing(9)};
        }

        &::after {
          padding-left: 0;
          content: '${suffix}';
          position: absolute;
          top: calc(50% - 10px);
          right: 0.5em;
          transition: all 0.05s ease-in-out;
        }
      `;
    }
  }}
`;

export const StyledInput = styled.input<InputProps>`
  ${({ theme, width = '100%', hasError }) => css`
    width: ${width};
    padding: ${theme.spacing(1.7)} ${theme.spacing(3)}};
    border-width: 1px;
    border-style: solid;
    border-color: ${theme.palette.fieldGrey};
    background-color: ${theme.palette.white};
    color: ${theme.palette.midnight};
    &:hover {
      border-color: ${theme.palette.midnight} ;
      outline: 0;
    }
    &:active,
    &:focus {
      border-color: ${hasError ? theme.palette.danger.main : theme.palette.primary.main} ;
      outline: 0;
    }

    &:invalid {
      border-color: ${theme.palette.danger.main};
      border-width: ${theme.sizing(0.5)};
    }

  `}
  ${({ noBorder }) => {
    if (noBorder) {
      return css`
        border: none;
        outline: none;
      `;
    }
  }}
  ${({ customSize, theme }) => {
    switch (customSize) {
      case 'small': {
        return css`
          height: ${theme.sizing(7)};
          font-size: ${theme.font.size.small};
          line-height: ${theme.font.lineheight.small};
          border-radius: ${theme.sizing(1)};
        `;
      }
      case 'medium': {
        return css`
          height: ${theme.sizing(9)};
          font-size: ${theme.font.size.medium};
          line-height: ${theme.font.lineheight.medium};
          border-radius: ${theme.sizing(2)};
        `;
      }
      case 'large': {
        return css`
          height: ${theme.sizing(11)};
          font-size: ${theme.font.size.medium};
          line-height: ${theme.font.lineheight.medium};
          border-radius: ${theme.sizing(2)};
        `;
      }
      default:
        return '';
    }
  }};
  ${({ theme, hasError }) => {
    if (hasError) {
      return css`
        border-color: ${theme.palette.danger.main};
        border-width: 2px;
      `;
    }
    return '';
  }}
  ${({ hideNumberArrows }) => {
    if (hideNumberArrows) {
      return css`
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type='number'] {
          -moz-appearance: textfield;
        }
      `;
    }
    return '';
  }}
`;

const Input = React.forwardRef<HTMLInputElement, React.PropsWithChildren<InputProps>>(
  (
    { customSize = 'small', selectAllOnMount, width, suffix, hideNumberArrows = false, label, disabled, ...other },
    ref,
  ) => {
    useEffect(() => {
      // @ts-ignore
      if (selectAllOnMount && ref.current) {
        // @ts-ignore
        ref.current.select();
      }
    }, []);

    return (
      <StyledInputContainer width={width} disabled={disabled} suffix={suffix}>
        {label && <Typography weight="regular">{label}</Typography>}
        <StyledInput
          ref={ref}
          width={width}
          customSize={customSize}
          hideNumberArrows={hideNumberArrows}
          disabled={disabled}
          {...other}
        />
      </StyledInputContainer>
    );
  },
);

export default Input;
