import { ReactNode } from 'react';
import { TextButtonProps } from 'scalexp/components/atoms/TextButton';

export enum BlockedStatus {
  CANCELLED = 'cancelled',
  LATE_PAYER = 'late_payer',
  TRIAL_ENDED = 'trial_ended',
}

export type Message = {
  title: string;
  text: ReactNode;
  action: TextButtonProps;
  bottomAction?: TextButtonProps;
};
