import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

export interface ImageButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  src: string;
  hoverSrc?: string;
  width?: string;
  height?: string;
}

export const StyledImageButton = styled.button<ImageButtonProps>`
  ${({ width, height, src }) => `
    height: ${height};
    width: ${width};
    padding: 0;
    margin: 0;
    border-radius: 4px;
    text-align: center;
    display: inline-block;
    background: url( /images/${src}) no-repeat 50% 50%;
    background-size: cover;
  `}
  ${({ hoverSrc }) =>
    !hoverSrc
      ? ''
      : `
    &:hover {
      background: url( /images/${hoverSrc}) no-repeat 50% 50%;
      background-size: cover;
    }
  `}
`;

const ImageButton: React.FC<React.PropsWithChildren<ImageButtonProps>> = ({
  children,
  width = '100%',
  height = '30px',
  ...other
}) => {
  return <StyledImageButton width={width} height={height} {...other} />;
};

export default ImageButton;
