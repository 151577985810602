import { useMemo } from 'react';

import { useConnectionsContext } from '.';
import { ConnectionName } from './types';

type SystemNames = {
  accountingSystemName?: ConnectionName;
  salesSystemName?: ConnectionName;
};

type AccountingAndSalesSystem = {
  isLoading: boolean;
  accountingSystem: string;
  accountingSystemName?: ConnectionName;
  salesSystem: string;
  salesSystemName?: ConnectionName;
  isStripeAvailable: boolean;
  isXero: boolean;
};

const FormalSourceNames: Record<ConnectionName, string> = {
  xero: 'Xero',
  quickbooks: 'QuickBooks',
  zohobooks: 'Zohobooks',
  salesforce: 'Salesforce',
  pipedrive: 'Pipedrive',
  hubspot: 'Hubspot',
  stripe: 'Stripe',
};

export const isConnectionName = (value: string): value is ConnectionName => value in FormalSourceNames;

export const formatConnectionName = (value: string) => (isConnectionName(value) ? FormalSourceNames[value] : value);

const STRIPE_CAPABLE_CONNECTIONS: readonly ConnectionName[] = ['xero', 'quickbooks'];
export const isStripeCapableConnection = (value: string) =>
  isConnectionName(value) && STRIPE_CAPABLE_CONNECTIONS.includes(value);

export const ACCOUNTING_CONNECTIONS: readonly ConnectionName[] = ['xero', 'quickbooks', 'zohobooks'];

export const CRM_CONNECTIONS = ['hubspot', 'salesforce', 'pipedrive'];
export const isCRMConnection = (value: string) => CRM_CONNECTIONS.includes(value);

const useGetAccountingAndSalesSystem = (): AccountingAndSalesSystem => {
  const { connections, isLoaded } = useConnectionsContext();

  return useMemo(() => {
    const { accountingSystemName, salesSystemName }: SystemNames = Object.entries(connections).reduce(
      (prev, [connectionName, connection]) => {
        if (connectionName === 'stripe' || connection.status === 'notconnected' || !isConnectionName(connectionName)) {
          return prev;
        }
        const key = isCRMConnection(connectionName) ? 'salesSystemName' : 'accountingSystemName';
        return { ...prev, [key]: connectionName };
      },
      { accountingSystemName: undefined, salesSystemName: undefined },
    );
    return {
      isLoading: !isLoaded,
      accountingSystem: formatConnectionName(accountingSystemName || ''),
      accountingSystemName,
      salesSystem: formatConnectionName(salesSystemName || ''),
      salesSystemName,
      isStripeAvailable: isStripeCapableConnection(accountingSystemName || ''),
      isXero: accountingSystemName === 'xero',
    };
  }, [connections, isLoaded]);
};

export default useGetAccountingAndSalesSystem;
